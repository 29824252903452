import { Box } from '@codecademy/gamut';
import React, {
  ComponentProps,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AnchorRefItemType, useMenuKeyboardNavigation } from '../../shared';

export interface AppHeaderMenuContextType {
  menuItems: Set<AnchorRefItemType>;
  setMenuItems: Dispatch<SetStateAction<Set<AnchorRefItemType>>> | undefined;
  resetIndex?: () => void;
}

export const AppHeaderMenuContext = createContext<AppHeaderMenuContextType>({
  menuItems: new Set(),
  setMenuItems: undefined,
});

export type AppHeaderMenuProviderProps = PropsWithChildren<{
  handleClose: () => void;
}> &
  Pick<ComponentProps<typeof Box>, 'as'>;

export const AppHeaderMenuProvider: React.FC<AppHeaderMenuProviderProps> = ({
  as,
  children,
  handleClose,
}) => {
  const [menuItems, setMenuItems] = useState<Set<AnchorRefItemType>>(
    () => new Set()
  );
  const [index, setIndex] = useState<number>(0);
  const [shouldFocus, setShouldFocus] = useState<boolean>(false);
  const menuItemsArray = Array.from(menuItems);

  const length = menuItems.size - 1;

  const first = () => {
    setShouldFocus(true);
    setIndex(0);
  };

  const back = (shouldFocus: boolean) => {
    setShouldFocus(shouldFocus);
    if (shouldFocus && index === 0) {
      // should only cycle for arrow keys, not shift tab
      setIndex(length);
    } else if (index !== 0) {
      setIndex(index - 1);
    }
  };

  const next = (shouldFocus: boolean) => {
    setShouldFocus(shouldFocus);
    if (index === length) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const last = () => {
    setShouldFocus(true);
    setIndex(0);
  };

  const resetIndex = () => {
    if (index !== 0) setIndex(0);
  };

  const closeDropdown = () => {
    handleClose();
    setShouldFocus(false);
    setIndex(0);
  };

  const checkUnsetFocusHandler = (
    event: React.FocusEvent<unknown, Element>
  ) => {
    if (event?.target === menuItemsArray[0] && index !== 0) {
      setIndex(0);
    }
    if (event?.target === menuItemsArray[length] && index !== length) {
      setIndex(length);
    }
  };

  const keyDown = useMenuKeyboardNavigation({
    first,
    back,
    next,
    last,
    closeDropdown,
  });

  useEffect(() => {
    const itemToFocus = menuItemsArray[index];
    if (itemToFocus && shouldFocus) {
      itemToFocus.focus();
      setShouldFocus(false);
    }
  }, [menuItemsArray, index, shouldFocus]);

  return (
    <AppHeaderMenuContext.Provider
      value={{ menuItems, resetIndex, setMenuItems }}
    >
      <Box
        as={as}
        onKeyDown={keyDown}
        onFocusCapture={(e) => checkUnsetFocusHandler(e)}
      >
        {children}
      </Box>
    </AppHeaderMenuContext.Provider>
  );
};

export const useAppHeaderMenuContext = () => useContext(AppHeaderMenuContext);
