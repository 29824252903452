import { MouseEvent } from 'react';

import { DropdownAnchor, DropdownIcon, StyledText } from '../../shared';
import { useAppHeaderDropdownContext } from '../AppHeaderDropdownProvider';

interface AppHeaderNavButtonProps {
  buttonRef: React.RefObject<HTMLButtonElement>;
  handleOnClick: (event: React.MouseEvent) => void;
  isOpen: boolean;
  title: string;
}

export const AppHeaderDropdownNavButton: React.FC<AppHeaderNavButtonProps> = ({
  buttonRef,
  handleOnClick,
  isOpen,
  title,
}) => {
  const { firstItemRef } = useAppHeaderDropdownContext();

  const dropdownNavOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    handleOnClick(e);
    if (!isOpen) firstItemRef?.focus();
  };

  return (
    <DropdownAnchor
      aria-expanded={isOpen}
      onClick={dropdownNavOnClick}
      ref={buttonRef}
      title={title}
      variant="interface"
    >
      <StyledText
        fontWeight={isOpen ? 'bold' : 'normal'}
        textAlign="center"
        title={title}
      >
        {title}
      </StyledText>
      <DropdownIcon open={isOpen} size={12} />
    </DropdownAnchor>
  );
};
