import { useTheme } from '@emotion/react';
import { AppHeaderNotificationSettings, Notification } from '@mono/brand';
import { logError } from '@mono/data/logging';
import {
  LoggedInUserResponseData,
  userIsLoggedIn,
  UserResponseData,
} from '@mono/data/user';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { trackEvent, trackUserClick } from '~/libs/tracking';

import { parseNotificationResponse } from './parseNotificationResponse';

export const useUserNotifications = (
  user: UserResponseData
): AppHeaderNotificationSettings | undefined => {
  const router = useRouter();
  const theme = useTheme();
  const userNotifications =
    user && userIsLoggedIn<LoggedInUserResponseData>(user)
      ? user.notifications
      : undefined;
  const [notifications, setNotifications] = useState<Notification[]>();

  useEffect(() => {
    if (userNotifications && !notifications) {
      setNotifications(
        userNotifications.map((notification) =>
          parseNotificationResponse(notification, theme.colors['blue-800'])
        )
      );
    }
  }, [notifications, theme, userNotifications]);

  const locationType = router.asPath.slice(1); // Removes the preceding '/'

  if (!notifications || !user || !userIsLoggedIn(user)) {
    return undefined;
  }

  return {
    actions: {
      clear: () => {
        setNotifications([]);
        fetch(
          `${
            process.env.NEXT_PUBLIC_NOTIFICATIONS_SERVICE_URL
          }/notifications?ids=${notifications
            .map((notification) => notification.id)
            .join(',')}`,
          {
            headers: [['Authorization', `Bearer ${user.jwt}`]],
            method: 'DELETE',
          }
        ).catch(logError);
      },
      click: (notification) => {
        trackEvent('notification', 'clicked', {
          context: notification.campaign,
          element: notification.type,
          event_id: notification.id,
          page_name: locationType,
          target: 'notification_bell_cta',
        });

        trackUserClick({
          context: 'topnav_bell',
          element: notification.type,
          page_name: locationType,
          slug: notification.campaign,
          target: 'notification_bell_cta',
        });
      },
      dismiss: (dismissed) => {
        fetch(
          `${process.env.NEXT_PUBLIC_NOTIFICATIONS_SERVICE_URL}/notifications?ids=${dismissed.id}`,
          {
            headers: [['Authorization', `Bearer ${user.jwt}`]],
            method: 'DELETE',
          }
        ).catch(logError);
        setNotifications(
          notifications.filter(
            (notification) => notification.id !== dismissed.id
          )
        );
      },
      read: (readNotifications) => {
        const readIds = readNotifications.map((read) => read.id);
        const readIdsSet = new Set(readIds);
        setNotifications(
          notifications.map((notification) =>
            readIdsSet.has(notification.id)
              ? { ...notification, unread: false }
              : notification
          )
        );
        fetch(
          `${
            process.env.NEXT_PUBLIC_NOTIFICATIONS_SERVICE_URL
          }/notifications?ids=${readIds.join(',')}`,
          {
            body: JSON.stringify({ status: 0 }),
            headers: [['Authorization', `Bearer ${user.jwt}`]],
            method: 'PATCH',
          }
        ).catch(logError);
      },
      track: (target) => {
        trackUserClick({ target, page_name: locationType });
      },
    },
    notifications,
    onEnable: () => {
      trackUserClick({
        context: 'global_nav',
        target: 'notification_bell',
      });
    },
  };
};
