import { useRouter } from 'next/router';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export const UserVisitContext = createContext<UserVisitContextProps>({
  referrerOverwrite: '',
});

interface UserVisitContextProviderProps {
  children: JSX.Element[];
}

export interface UserVisitContextProps {
  referrerOverwrite: string;
}

export const useUserVisitContext = () => useContext(UserVisitContext);

export const UserVisitProvider: FC<UserVisitContextProviderProps> = ({
  children,
}) => {
  const { asPath } = useRouter();
  const pagePathWithTrailingSlash = asPath[0] !== '/' ? `/${asPath}` : asPath;
  const pagePath =
    process.env.NEXT_PUBLIC_BASE_PATH + pagePathWithTrailingSlash;

  const [contextValue, setContextValue] = useState('');
  const previousPath = useRef(contextValue);

  useEffect(() => {
    if (previousPath.current !== pagePath) {
      setContextValue(pagePath);
      previousPath.current = contextValue;
    }
  }, [pagePath, contextValue]);

  return (
    <UserVisitContext.Provider
      value={{ referrerOverwrite: previousPath.current }}
    >
      {children}
    </UserVisitContext.Provider>
  );
};
