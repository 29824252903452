import { Experiments } from '@mono/data/optimizely';
import React, { useContext } from 'react';

type ExperimentsContextValue = {
  experiments: Experiments;
};

export const ExperimentsContext = React.createContext<ExperimentsContextValue>({
  experiments: {},
});

export const useExperimentsContext = () => useContext(ExperimentsContext);
