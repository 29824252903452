/*
 * A web worker to handle search-as-you-type and autocomplete
 *
 * This worker implements an algorithm for text matching that tests
 * a query string against a set of titles, and gives a score to
 * each character in a given title.
 *
 * e.g. matching query "abcde" to title "ab cde"
 * "a" and "b" each get a score of 2, because each is part of the match "ab"
 * "c", "d", and "e" each get a score of 3, because each is part of the match "cde"
 *
 * For autocomplete, a large list of titles is preloaded, and this
 * scoring algorythm is used both to find the top 5 matches, and to
 * determine which characters should be highlighted.
 * (These titles have certain phrases like "Intro to" removed so that they
 * better resemble something that a user would type.)
 *
 * For search-as-you-type, we rely on typesense to find matches,
 * but we still use this algorythm for character highlighting.
 */

export function getPortalOrigin() {
  const { origin } = window.location;
  // for standard envs, use portal app in the same env
  if (
    ['tengu', 'tayra', 'staging', 'www']
      .map((s) => `https://${s}.codecademy.com`)
      .includes(origin)
  ) {
    return origin;
  }

  // for local, use local portal-app
  if (origin.includes('localhost')) {
    return origin.replace(/:\d{4}/, ':3100'); // replace if origin port is monolith or le
  }

  // otherwise (e.g. in monolith pr env) use staging portal-app
  return 'https://staging.codecademy.com';
}

export function getSearchWorkerSrc() {
  const { search } = window.location;
  const BASE_URL = `${getPortalOrigin()}/api/portal/search`;
  return `
    const splitWords = /[ :-]/g;

    function getWordSet(str) {
      const words = str.split(splitWords);
      const filtered = words.map((w) => w.trim()).filter(Boolean);
      return new Set(filtered);
    }

    function preparseTitle({ value: title, popularity }) {
      const titleLower = title.toLowerCase();
      const titleWordSet = getWordSet(titleLower);
      return { title, titleLower, titleWordSet, popularity };
    }

    async function preloadAutocompleteTitles() {
      const f = await fetch('${BASE_URL}/autocomplete-preload/${search}');
      const titles = await f.json();
      return titles.map(preparseTitle);
    }

    // Used by autocomplete, both for finding top 5, and for highlighting
    // Used by search-as-you-type, only for highlighting
    // Returns number[], where each number is the match score for a char in the title
    function getTitleCharScores(query, queryWordSet, titleLower) {
      // prefill with an empty array for each character in title
      const overlap = Array(titleLower.length)
        .fill(0)
        .map(() => []);

      for (
        let queryShift = 1 - query.length;
        queryShift < titleLower.length;
        queryShift++
      ) {
        let cmc = null; // consecutive matching characters
        const startOverlap = Math.max(queryShift, 0);
        const endOverlap = Math.min(query.length + queryShift, titleLower.length);
        for (let i = startOverlap; i < endOverlap; i++) {
          if (query[i - queryShift] === titleLower[i] && titleLower[i] !== ' ') {
            if (!cmc) {
              // start a new cmc
              cmc = { str: '' };
            }
            // append this char to the current cmc
            cmc.str += query[i - queryShift];
            overlap[i].push(cmc);
          } else if (cmc) {
            cmc = null; // set to null so that the next match starts a new cmc
          }
        }
      }

      const avgQueryWordLength = query.length / queryWordSet.size;

      // If avgQueryWordLength is 1-3, we want to the min match to be about that same size.
      // If avgQueryWordLength is longer, we want min match to be longer...but not too long.
      // e.g. For avgQueryWordLength of 9, min should be 5
      // The misspelling "regresion" should still match "regression", but not "encryption",
      // "authentication" or other words ending in "ion"
      const minMatchLength = Math.ceil(avgQueryWordLength ** 0.65);

      const titleCharScores = [];
      for (const cmcs of overlap) {
        let score = 0;
        for (const cmc of cmcs) {
          // if the string of consecutive matching chars meets the minMatchLength
          // or if it's a standalone word in the query (e.g "C")
          if (cmc.str.length >= minMatchLength || queryWordSet.has(cmc.str)) {
            score += cmc.str.length;
          }
        }
        titleCharScores.push(score);
      }
      return titleCharScores;
    }

    // Total used in sorting to find autocomplet top 5
    // Bonus scores are includes in certain cases
    function getTotalScore(
      query,
      queryWordSet,
      titleLower,
      titleWordSet,
      titleCharScores
    ) {
      let fromChars = 0;
      for (const s of titleCharScores) {
        fromChars += s;
      }

      let bonus = 0;
      // for each complete word present in both the title and query
      for (const qw of queryWordSet) {
        if (titleWordSet.has(qw)) {
          bonus += 100;
        }
      }

      // if the title starts with the query
      if (titleLower.startsWith(query)) {
        bonus += 100;
      }

      // if the title is an exact match with the query
      if (titleLower === query) {
        bonus += 100;
      }

      return fromChars + bonus;
    }

    // returns { value: string, highlight: boolean }[]
    function getHighlightSegments(title, titleCharScores) {
      let currentSegment = null;
      const segments = [];

      const shouldHighlight = (i) => titleCharScores[i] > 0;

      const isDifferentFromPrev = (i) =>
        shouldHighlight(i - 1) !== shouldHighlight(i);

      for (let i = 0; i < title.length; i++) {
        if (i === 0 || isDifferentFromPrev(i)) {
          // If this char should be highlighted, but the prev one should not
          // or vice versa, start a new segment.
          currentSegment = { value: '', highlight: shouldHighlight(i) };
          segments.push(currentSegment);
        }
        currentSegment.value += title[i];
      }
      return segments;
    }

    const preloadPromise = preloadAutocompleteTitles();

    onmessage = ({ data }) => {
      const { action, query, max } = data;
      const queryWordSet = getWordSet(query);
      if (action === 'autocomplete') {
        processAutcomplete(query, queryWordSet, max);
      }
      if (action === 'search-as-you-type') {
        processSearchAsYouType(query, queryWordSet, max);
      }
    };

    async function processAutcomplete(query, queryWordSet, max) {
      const autocompleteTitles = await preloadPromise;

      const scoredAutocompleteTitles = autocompleteTitles.map(
        ({ title, titleLower, titleWordSet, popularity }) => {
          const titleCharScores = getTitleCharScores(
            query,
            queryWordSet,
            titleLower
          );
          const titleScore = getTotalScore(
            query,
            queryWordSet,
            titleLower,
            titleWordSet,
            titleCharScores
          );
          return { title, titleCharScores, titleScore, popularity };
        }
      );

      /*
      * For the search "alge", .04 is just low enough so that "Linear algebra"
      * (which matches on 4 letters) comes in above "Data structures and algorithms"
      * (which only matches on 3 letters, but has a high popularity score).
      */
      const popularityStrength = 0.04;

      const topAutocompleteTitles = scoredAutocompleteTitles
        .filter((x) => x.titleScore > 0)
        .sort((a, b) =>
          a.titleScore + (a.popularity || 0) * popularityStrength >
          b.titleScore + (b.popularity || 0) * popularityStrength
            ? -1
            : 1
        )
        .slice(0, max)
        .map(({ title, titleCharScores }) => ({
          title,
          segments: getHighlightSegments(title, titleCharScores),
          key: query + ':' + title,
        }));

      postMessage({ query, topAutocompleteTitles });
    }

    async function processSearchAsYouType(query, queryWordSet, max) {
      const f = await fetch('${BASE_URL}/search-as-you-type/${search}', {
        body: JSON.stringify({ query, max }),
        method: 'POST',
      });
      const searchAsYouTypeResults = await f.json();
      for (const entry of searchAsYouTypeResults.top) {
        const charScores = getTitleCharScores(
          query,
          queryWordSet,
          entry.title.toLowerCase()
        );
        entry.segments = getHighlightSegments(entry.title, charScores);
        entry.key = query + ':' + entry.title;
      }
      postMessage({ query, searchAsYouTypeResults });
    }
  `;
}
