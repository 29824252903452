// used to create slugs for both playlists and videos
export const titleToSlug = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/.*?: /, '') // remove prefixes
    .replaceAll('c#', 'c sharp')
    .replaceAll('c++', 'c plus plus')
    .replaceAll('.net', ' dotnet')
    .replaceAll('%', ' percent')
    .replace(/["']/g, '') // remove apostrophes and quotes
    .replace(/[&+]/g, ' and ') // replace & and + with "and"
    .replace(/[^a-z0-9 ]/g, ' ') // replace non-space non-alphanumeric chars with space
    .replace(/ +/g, ' ') // deduplicate spaces
    .trim()
    .replaceAll(' ', '-'); // replace spaces with dashes
};
