import { Box } from '@codecademy/gamut';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { AnchorRefItemType, useOnEscHandler } from '../../shared';

export interface AppHeaderDropdownContextType {
  firstItemRef?: AnchorRefItemType;
  setFirstItemRef: Dispatch<SetStateAction<AnchorRefItemType>> | undefined;
}

export const AppHeaderDropdownContext =
  createContext<AppHeaderDropdownContextType>({
    firstItemRef: undefined,
    setFirstItemRef: undefined,
  });

export const AppHeaderDropdownProvider: React.FC<{
  children: ReactNode;
  handleClose: () => void;
}> = ({ children, handleClose }) => {
  const [firstItemRef, setFirstItemRef] = useState<
    AnchorRefItemType | undefined
  >(undefined);

  const onKeyDown = useOnEscHandler(handleClose);

  return (
    <AppHeaderDropdownContext.Provider
      value={{ firstItemRef, setFirstItemRef }}
    >
      <Box onKeyDown={onKeyDown}>{children}</Box>
    </AppHeaderDropdownContext.Provider>
  );
};

export const useAppHeaderDropdownContext = () =>
  useContext(AppHeaderDropdownContext);
