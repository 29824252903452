import { Box, GridBox, StrokeButton } from '@codecademy/gamut';
import {
  EmailFilledIcon,
  FacebookIcon,
  WhatsAppFilledIcon,
  XIcon,
} from '@codecademy/gamut-icons';

import { createShareLink, SocialMediaShare } from '../../../SocialMediaSharing';
import { onClickWrapper } from '../../../SocialMediaSharingDropdown';

const referralMessage =
  'Use my referral link to get 50% off a Pro, Plus, or Pro Student annual plan';

const socialLinks = [
  {
    id: 'x',
    label: 'X',
    icon: XIcon,
    formatShare: (referralUrl: string) =>
      createShareLink(
        ({ url }: SocialMediaShare) => ({
          ...(url && { url }),
          text: referralMessage,
          hashtags: 'codecademy',
          via: 'codecademy',
        }),
        'https://twitter.com/intent/tweet?',
        { url: referralUrl }
      ),
  },
  {
    id: 'facebook',
    label: 'Facebook',
    icon: FacebookIcon,
    formatShare: (referralUrl: string) =>
      createShareLink(
        ({ url }: SocialMediaShare) => ({
          ...(url && { href: url }),
          quote: referralMessage,
          hashtag: '#codecademy',
        }),
        'https://www.facebook.com/dialog/share?app_id=212500508799908',
        { url: referralUrl }
      ),
  },
  {
    id: 'whatsApp',
    label: 'WhatsApp',
    icon: WhatsAppFilledIcon,
    formatShare: (referralUrl: string) =>
      createShareLink(
        ({ url }: SocialMediaShare) => ({
          text: `${referralMessage}: ${url}`,
        }),
        'https://wa.me/?',
        { url: referralUrl }
      ),
  },
  {
    id: 'email',
    label: 'Email',
    icon: EmailFilledIcon,
    formatShare: (url: string) => {
      const subject = encodeURIComponent(
        'Join me on Codecademy and get 50% off'
      );
      const body = encodeURIComponent(
        `Hey! I thought you would be interested in learning to code and building skills to grow your career. If you sign up using my referral link, you'll get 50% off a Codecademy Pro, Plus, or Pro Student plan for a year: ${url}`
      );
      return `mailto:?subject=${subject}&body=${body}`;
    },
  },
];

export const ReferralLinkSocialMediaSharing: React.FC<{
  referralUrl: string;
}> = ({ referralUrl }) => (
  <GridBox
    gridTemplateColumns="1fr 1fr"
    columnGap={12}
    rowGap={12}
    as="ul"
    listStyle="none"
    m={0}
    p={0}
  >
    {socialLinks.map(({ id, label, icon: IconComponent, formatShare }) => (
      <Box as="li" key={id}>
        <StrokeButton
          aria-label={`Share referral link via ${label}`}
          width="100%"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            onClickWrapper(e, id, formatShare(referralUrl))
          }
        >
          <IconComponent size={12} mr={8} />
          {label}
        </StrokeButton>
      </Box>
    ))}
  </GridBox>
);
