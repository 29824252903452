import {
  ConnectedFormGroup,
  ConnectedInput,
  useFormState,
} from '@codecademy/gamut';
import React, { useEffect } from 'react';

export const ReferralFields: React.FC<{}> = () => {
  const { setFocus } = useFormState();

  useEffect(() => {
    setFocus('firstName');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConnectedFormGroup
        name="firstName"
        label="First name"
        field={{ component: ConnectedInput }}
        required
      />
      <ConnectedFormGroup
        name="email"
        label="Email address"
        field={{ component: ConnectedInput }}
        required
      />
    </>
  );
};
