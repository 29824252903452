import { WithChildrenProp } from '@codecademy/gamut';
import {
  getOptimizelyClient,
  OptimizelyFromServer,
  useOptimizelyUserAttributes,
} from '@mono/data/optimizely';
import { isOnServer } from '@mono/util/env';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { useMemo } from 'react';

interface OptimizelyProviderWrapperProps extends WithChildrenProp {
  optimizely?: OptimizelyFromServer;
}

export const OptimizelyProviderWrapper: React.FC<
  OptimizelyProviderWrapperProps
> = ({ optimizely, children }) => {
  const datafile = optimizely?.datafile;
  const initialUser = optimizely?.optimizelyUser;
  const isTestUser = Boolean(initialUser?.attributes.optimizely_test_user);

  const optimizelyClient = useMemo(() => {
    // if we're on the server and don't have a datafile, we don't want to initialize a client
    if (!datafile && isOnServer()) return undefined;

    return getOptimizelyClient(datafile || undefined, undefined, isTestUser);
  }, [datafile, isTestUser]);

  const user = useOptimizelyUserAttributes(initialUser);

  /**
   * OptimizelyProvider sets the user for us, but only updates the user after mount.
   * So on the first pageview after the user updates, we are rebucketing the user
   * instead of getting their previous variation from the user profile service.
   * As a workaround, we are manually setting the user here since we always have the most
   * up to date user from the server.
   * possibly related to https://github.com/optimizely/react-sdk/issues/87#issuecomment-938229504
   */
  useMemo(() => {
    if (optimizelyClient && user) {
      optimizelyClient.setUser(user);
    }
  }, [user, optimizelyClient]);

  if (!optimizelyClient) {
    return <>{children}</>;
  }

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={user}
      isServerSide={isOnServer()}
    >
      {children}
    </OptimizelyProvider>
  );
};
