import { Anchor, Column, FlexBox, LayoutGrid, Text } from '@codecademy/gamut';
import { Background, css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import * as React from 'react';
import { RefObject, useEffect, useRef } from 'react';

import {
  AppHeaderAction,
  AppHeaderLinkItem,
  AppHeaderResourcesDataItem,
  DescriptionSectionContainer,
  LayoutGridAntiAliased,
  useMegaMenuHeaderResponsiveStyles,
} from '../../shared';
import { useAppHeaderDropdownContext } from '../AppHeaderDropdownProvider';

export type AppHeaderResourcesSectionProps = AppHeaderAction & {
  resources: AppHeaderResourcesDataItem[];
  ref?: RefObject<HTMLUListElement>;
  role?: string;
  id?: string;
  keyDownEvents?: (event: React.KeyboardEvent) => void;
  isOpen?: boolean;
  handleClose?: () => void;
};

const StyledColumn = styled(Column)(
  css({ borderBottom: 1, borderColor: 'text' })
);

export const AppHeaderResourcesSection = React.forwardRef<
  HTMLDivElement,
  AppHeaderResourcesSectionProps
>(({ resources, action, isOpen, keyDownEvents, handleClose }, ref) => {
  const tabIndex = isOpen === false ? -1 : 0;
  const styles = useMegaMenuHeaderResponsiveStyles();
  const { setFirstItemRef } = useAppHeaderDropdownContext();
  const firstElemRef = useRef<HTMLAnchorElement>(null);

  const onClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    linkItem: AppHeaderLinkItem
  ) => {
    handleClose?.();
    return action(event, linkItem);
  };

  useEffect(() => {
    if (setFirstItemRef && firstElemRef.current) {
      setFirstItemRef(firstElemRef.current);
    }
  }, [setFirstItemRef, firstElemRef]);

  const DescriptionSection: React.FC<{
    title: string;
    subtitle?: string;
  }> = ({ title, subtitle }) => (
    <DescriptionSectionContainer
      data-focusableresource="true"
      tabIndex={-1}
      flexDirection="column"
    >
      <Text
        as="h2"
        variant="title-xs"
        pr={{ _: 96, md: 16 }}
        mr={{ _: 96, md: 0 }}
      >
        {title}
      </Text>
      {subtitle && (
        <Text fontSize={14} my={8}>
          {subtitle}
        </Text>
      )}
    </DescriptionSectionContainer>
  );

  return (
    <LayoutGridAntiAliased onKeyDown={keyDownEvents} ref={ref} as="ul" p={0}>
      {resources.map((section, sectionIndex) => {
        const { title, description, links, data } = section;
        const borderBottom = sectionIndex === resources.length - 1 ? 'none' : 1;

        return (
          <StyledColumn size={12} key={title} as="li">
            <LayoutGrid>
              <Column size={{ xs: 12, md: 3 }}>
                <Background
                  {...styles}
                  px={{ _: 16, xs: 32 }}
                  py={{ _: 16, sm: 24 }}
                  borderBottom={borderBottom}
                >
                  <DescriptionSection title={title} subtitle={description} />
                  {links && (
                    <FlexBox flexDirection="column" alignItems="flex-start">
                      {links.map((link, linkIndex) => {
                        return (
                          <Anchor
                            variant="standard"
                            fontSize={14}
                            fontWeight={700}
                            textAlign={{ _: 'center', md: 'left' }}
                            href={link.href}
                            data-focusableresource="true"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>
                            ) => onClick(event, link)}
                            tabIndex={tabIndex}
                            mt={{ _: 16, lg: description ? 16 : 24 }}
                            target={link.newTab ? '_blank' : '_self'}
                            key={link.id}
                            ref={
                              sectionIndex === 0 && linkIndex === 0
                                ? firstElemRef
                                : undefined
                            }
                          >
                            {link.text}
                            <span aria-hidden>&nbsp;→</span>
                          </Anchor>
                        );
                      })}
                    </FlexBox>
                  )}
                </Background>
              </Column>
              <Column size={{ xs: 12, md: 8 }}>
                <LayoutGrid
                  as="ul"
                  pt={32}
                  pb={24}
                  pl={{ _: 16, sm: 64, md: 48 }}
                >
                  {data.map((item) => {
                    const { id, href, text, badge, newTab } = item;

                    return (
                      <Column as="li" key={id} size={{ _: 12, lg: 4 }}>
                        <Anchor
                          data-focusableresource="true"
                          variant="interface"
                          href={href}
                          onClick={(
                            event: React.MouseEvent<HTMLAnchorElement>
                          ) => onClick(event, item)}
                          tabIndex={tabIndex}
                          target={newTab ? '_blank' : '_self'}
                        >
                          {'description' in item ? (
                            <>
                              <FlexBox whiteSpace="nowrap">
                                <Text
                                  fontSize={16}
                                  fontWeight="bold"
                                  as="h3"
                                  pb={4}
                                >
                                  {text}
                                </Text>
                                {badge}
                              </FlexBox>
                              <Text fontSize={14} pb={24} pr={40}>
                                {item.description}
                              </Text>
                            </>
                          ) : (
                            <Text fontSize={16} pb={8}>
                              {text}
                            </Text>
                          )}
                        </Anchor>
                      </Column>
                    );
                  })}
                </LayoutGrid>
              </Column>
            </LayoutGrid>
          </StyledColumn>
        );
      })}
    </LayoutGridAntiAliased>
  );
});
