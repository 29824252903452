import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { isLocalDevBuild, isOnServer, isProductionBuild } from '@mono/util/env';

import { markAsError } from './datadog-apm';
import { toError } from './errors';

export const initErrorLogging = (
  config: LogsInitConfiguration,
  isEnabled = false
) => {
  if (isProductionBuild() && isEnabled) {
    datadogLogs.init({
      forwardErrorsToLogs: false,
      ...config,
    });
  }
};

export type LogErrorContext =
  | {
      /**
       * Error message override
       */
      message?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [k: string]: any;
    }
  | string;

export const logAndReturnError = (
  error: unknown,
  context?: LogErrorContext
) => {
  const errorInstance = toError(error);
  if (typeof context === 'string') {
    context = { message: context };
  }
  if (context?.message) {
    // Keep error instance but override with custom message
    errorInstance.message = context.message;
  }
  if (isOnServer()) {
    // eslint-disable-next-line no-console
    console.error(errorInstance);
    markAsError(errorInstance);
  } else {
    // eslint-disable-next-line no-console
    if (isLocalDevBuild()) console.error(errorInstance);
    datadogLogs.logger.error(errorInstance.message, context);
    datadogRum.addError(errorInstance, context);
  }
  return errorInstance;
};

export const logWarn = (message: string, context?: object) => {
  if (isOnServer()) {
    // eslint-disable-next-line no-console
    console.warn(message);
  } else {
    // eslint-disable-next-line no-console
    if (isLocalDevBuild()) console.warn(message);
    datadogLogs.logger.warn(message, context);
  }
};

export const logInfo = (message: string, context?: object) => {
  if (isOnServer()) {
    // eslint-disable-next-line no-console
    console.log(message);
  } else {
    // eslint-disable-next-line no-console
    if (isLocalDevBuild()) console.info(message);
    datadogLogs.logger.info(message, context);
  }
};

export const logError = (error: unknown, context?: LogErrorContext) => {
  logAndReturnError(error, context);
};

export const logAction = (name: string, context?: object) => {
  datadogRum.addAction(name, context);
};
