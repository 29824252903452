import {
  Box,
  FillButton,
  FlexBox,
  Modal,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { AlertIcon } from '@codecademy/gamut-icons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import { trackUserClick, trackUserImpression } from '~/libs/tracking';

interface OnetimeDunningProps {
  currentRenewalDate: string;
  paymentLink: string;
  cancellationLink: string;
  isPro: boolean;
  canViewModal: boolean;
}

export const OnetimePastDueDunningModal: React.FC<OnetimeDunningProps> = ({
  currentRenewalDate,
  paymentLink,
  cancellationLink,
  isPro,
  canViewModal,
}) => {
  const [showPastDueModal, setPastDueModal] = useState(true);
  const location = useLocation();

  const planName = isPro ? 'Pro' : 'Plus';
  const formattedCurrentRenewalDate = currentRenewalDate
    ? format(new Date(currentRenewalDate), 'MMMM d, yyyy')
    : '';

  const line1 = `Your ${planName} ended on ${formattedCurrentRenewalDate}.`;
  const line2 = `To keep learning with Codecademy ${planName}, please renew your plan.`;

  const trackClick = () =>
    trackUserClick({
      target: 'renew_subscription',
      page_name: 'renew_plan_overlay',
    });

  const onClose = () => {
    trackUserClick({
      target: 'close',
      page_name: 'renew_plan_overlay',
    });
    setPastDueModal(false);
  };

  const onDontRenewClose = () => {
    trackUserClick({
      target: 'i_dont_want_to_renew',
      page_name: 'renew_plan_overlay',
    });
    setPastDueModal(false);
  };

  const dontRenewButton = (
    <TextButton onClick={onDontRenewClose} href={cancellationLink} mr={16}>
      {`I don't want to renew`}
    </TextButton>
  );

  const renewNowButton = (
    <FillButton
      onClick={trackClick}
      href={paymentLink + '?source=' + location.pathname ?? ''}
    >
      Renew now
    </FillButton>
  );

  useEffect(() => {
    if (showPastDueModal && canViewModal) {
      trackUserImpression({
        page_name: 'renew_plan_overlay',
        target: 'dunning_popup',
      });
    }
  }, [showPastDueModal, canViewModal]);

  return (
    <Modal
      isOpen={canViewModal && showPastDueModal}
      size="medium"
      onRequestClose={onClose}
      title={
        <FlexBox row alignItems="center">
          <AlertIcon
            color="danger"
            aria-label="Alert"
            aria-hidden={false}
            mr={16}
            size="20"
          />{' '}
          <Text>Renew your plan</Text>
        </FlexBox>
      }
    >
      <FlexBox column>
        <Box>
          <Text mb={12}>{line1}</Text>
          <Text mb={12}>{line2}</Text>
        </Box>
      </FlexBox>
      <Box height={16} />
      <FlexBox justifyContent="end" alignItems="end">
        {dontRenewButton}
        {renewNowButton}
      </FlexBox>
    </Modal>
  );
};
