import { logError } from '@mono/data/logging';
import {
  ActivateListenerPayload,
  NotificationListener,
} from '@optimizely/optimizely-sdk';
import { GetServerSidePropsContext } from 'next';

import envsInternalExternal from './envsInternalExternal';

import { cfAccessHeaders } from '@mono/data/cf-access-headers';

export const createOnActivate =
  (
    context?: GetServerSidePropsContext
  ): NotificationListener<ActivateListenerPayload> =>
  ({ experiment, userId, attributes, variation }) => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `${envsInternalExternal.MONOLITH_URL}/optimizely/on_assignment`,
          {
            method: 'POST',
            headers: {
              cookie: context?.req?.headers?.cookie || '', // server side
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Credentials: 'include', // client side
              ...cfAccessHeaders,
            },
            body: JSON.stringify({
              experiment,
              experimentation_id: userId,
              attributes,
              variation,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed for exp:${experiment.key}, received error status:${
              response.status || 'unknown'
            } msg:${response.statusText || 'unknown'}`
          );
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          logError(err, {
            message: `Failed to track experiment assignment: ${err.message}`,
          });
        }
      }
    };
    sendRequest();
  };
