import {
  ButtonBaseElements,
  FlexBox,
  IconButton,
  Text,
} from '@codecademy/gamut';
import { BellIcon } from '@codecademy/gamut-icons';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import { useAppHeaderContext } from '../AppHeader/AppHeaderElements/AppHeaderProvider';
import { Notification } from '../NotificationList/typings';

export type NotificationBellProps = {
  bellRef: React.RefObject<ButtonBaseElements>;
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  notifications: Notification[];
};

const notificationsID = 'notifications';

export const NotificationBell: React.FC<NotificationBellProps> = ({
  bellRef,
  isOpen,
  setIsOpen,
  notifications,
}) => {
  const { lastOpenedDropdown, setLastOpenedDropdown } = useAppHeaderContext();

  const unread = notifications.filter(
    (notification) => notification.unread
  ).length;

  useEffect(() => {
    const favicon = window?.document?.querySelector<HTMLLinkElement>(
      'link[rel="shortcut icon"]'
    );

    if (favicon) {
      favicon.href = `/favicon${unread ? '-unread' : ''}.ico`;
    }
  }, [unread]);

  useEffect(() => {
    if (lastOpenedDropdown !== notificationsID && isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOpenedDropdown]);

  const numOfUnread = useMemo(() => Math.min(unread, 99), [unread]);

  const handleOnClick = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      if (setLastOpenedDropdown) {
        setLastOpenedDropdown(notificationsID);
      }
    }
  };

  return (
    <>
      <IconButton
        aria-expanded={Boolean(isOpen)}
        aria-haspopup="dialog"
        tip={
          unread
            ? `Notifications, ${numOfUnread} unread`
            : 'Notifications, no unread'
        }
        icon={BellIcon}
        onClick={handleOnClick}
        ref={bellRef}
        variant="interface"
        tipProps={{ alignment: 'bottom-center', placement: 'floating' }}
      />
      {unread ? (
        <FlexBox
          alignItems="center"
          bg="red-500"
          borderRadius="md"
          height={18}
          justifyContent="center"
          left={22}
          position="absolute"
          textAlign="center"
          top={-2}
          width={20}
        >
          <Text color="white" fontSize={14} fontWeight="bold" mt={4}>
            {numOfUnread}
          </Text>
        </FlexBox>
      ) : null}
    </>
  );
};
