import {
  FillButton,
  IconButton,
  Popover,
  PopoverProps,
  StrokeButton,
  TextButton,
} from '@codecademy/gamut';
import { buttonProps } from '@codecademy/gamut/dist/Button/shared';
import {
  ArrowChevronDownFilledIcon,
  MiniKebabMenuIcon,
} from '@codecademy/gamut-icons';
import { css, pxRem, styledOptions } from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';
import styled from '@emotion/styled';
import { PropsWithChildren, useId, useRef, useState } from 'react';
import * as React from 'react';

import { DropdownList, DropdownListProps } from './DropdownList';
import { useDynamicPopoverPosition } from './useDynamicPopoverPosition';

const DownArrow = styled(ArrowChevronDownFilledIcon, styledOptions)<{
  isOpen?: boolean;
}>`
  margin-left: ${pxRem(8)};
  transition: transform 0.35s ease-out;
  ${({ isOpen }) => isOpen && 'transform: rotate(-180deg)'};
`;

const HorizontalKebabIcon = styled(MiniKebabMenuIcon)(
  css({
    transform: 'rotate(90deg)',
  })
);

export type DropdownButtonProps = PropsWithChildren<
  {
    align?: PopoverProps['align'];
    buttonType?: 'fill' | 'stroke' | 'text' | 'kebab' | 'horizontalKebab';
    defaultPosition?: PopoverProps['position'];
    dropdownItems: DropdownListProps['dropdownItems'];
    horizontalOffset?: number;
    /**
     * If the dropdown should semantically be a menu
     */
    isMenu?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    size?: number;
    verticalOffset?: number;
  } & StyleProps<typeof buttonProps>
>;

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  align = 'left',
  buttonType = 'fill',
  children,
  defaultPosition = 'below',
  dropdownItems,
  horizontalOffset,
  isMenu,
  onClick,
  verticalOffset = 0,
  size,
  ...buttonStyleProps
}) => {
  const buttonId = useId();
  const targetRef = useRef<HTMLAnchorElement | HTMLButtonElement>(null);
  const { position, popoverContainerRefCallback } = useDynamicPopoverPosition({
    targetRef,
    verticalOffset,
    defaultPosition,
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (event: React.MouseEvent) => {
    if (!isOpen) {
      onClick?.(event);
    }
    setIsOpen(!isOpen);
  };
  const handleRequestClosed = () => {
    setIsOpen(false);
  };

  let clickTarget: React.ReactNode;
  switch (buttonType) {
    case 'fill':
      clickTarget = (
        <FillButton
          ref={targetRef}
          aria-expanded={isOpen}
          id={buttonId}
          onClick={handleClick}
          data-testid="dropdown-fill-button"
          {...(buttonStyleProps ?? {})}
        >
          {children}
          <DownArrow isOpen={isOpen} size={size || 12} />
        </FillButton>
      );
      break;
    case 'stroke':
      clickTarget = (
        <StrokeButton
          ref={targetRef}
          aria-expanded={isOpen}
          id={buttonId}
          onClick={handleClick}
          data-testid="dropdown-stroke-button"
          {...(buttonStyleProps ?? {})}
        >
          {children}
          <DownArrow isOpen={isOpen} size={size || 12} />
        </StrokeButton>
      );
      break;
    case 'text':
      clickTarget = (
        <TextButton
          ref={targetRef}
          id={buttonId}
          onClick={handleClick}
          data-testid="dropdown-stroke-button"
          {...(buttonStyleProps ?? {})}
        >
          {children}
          <DownArrow isOpen={isOpen} size={12} />
        </TextButton>
      );
      break;
    case 'kebab':
      clickTarget = (
        <IconButton
          ref={targetRef}
          aria-expanded={isOpen}
          aria-haspopup="menu"
          id={buttonId}
          icon={() => <MiniKebabMenuIcon size={size} />}
          size="small"
          variant="secondary"
          onClick={handleClick}
          data-testid="dropdown-kebab-button"
          tip="Show options"
          {...(buttonStyleProps ?? {})}
        />
      );
      break;
    case 'horizontalKebab':
      clickTarget = (
        <IconButton
          aria-label="Options"
          ref={targetRef}
          aria-expanded={isOpen}
          aria-haspopup="menu"
          id={buttonId}
          icon={() => <HorizontalKebabIcon size={size} />}
          size="small"
          variant="secondary"
          onClick={handleClick}
          data-testid="dropdown-horizontal-kebab-button"
          tip="Show more options"
          {...(buttonStyleProps ?? {})}
        />
      );
  }

  return (
    <>
      {clickTarget}
      {dropdownItems.length !== 0 && (
        <Popover
          popoverContainerRef={popoverContainerRefCallback}
          targetRef={targetRef}
          isOpen={isOpen}
          onRequestClose={handleRequestClosed}
          align={align}
          verticalOffset={verticalOffset}
          horizontalOffset={horizontalOffset}
          position={position}
          outline
        >
          <DropdownList
            dropdownItems={dropdownItems}
            isMenu={isMenu}
            menuButtonId={buttonId}
            onClose={handleRequestClosed}
          />
        </Popover>
      )}
    </>
  );
};
