import { isOnServer } from '@mono/util/env';

// Refer README.md: .env files
export default {
  MONOLITH_GRAPHQL_URL: isOnServer()
    ? process.env.MONOLITH_GRAPHQL_URL
    : process.env.NEXT_PUBLIC_MONOLITH_GRAPHQL_URL,
  GRAPHQL_GATEWAY_URL: isOnServer()
    ? process.env.GRAPHQL_GATEWAY_URL
    : process.env.NEXT_PUBLIC_GRAPHQL_GATEWAY_URL,
  MONOLITH_URL: isOnServer()
    ? process.env.MONOLITH_URL
    : process.env.NEXT_PUBLIC_MONOLITH,
};
