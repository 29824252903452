import {
  Anchor,
  Badge,
  Box,
  Column,
  FlexBox,
  GridBox,
  LayoutGrid,
  Text,
} from '@codecademy/gamut';
import { MiniArrowRightIcon } from '@codecademy/gamut-icons';
import { TinyBlocks } from '@codecademy/gamut-illustrations';
import { Background } from '@codecademy/gamut-styles';
import * as React from 'react';
import { RefObject, useContext, useEffect, useRef } from 'react';

import { UnifiedRecFlowV2Context } from '../../../GlobalHeader';
import {
  careerPaths,
  getTopSubjects,
  topLanguages,
} from '../../../lib/catalogList';
import {
  AppHeaderAction,
  AppHeaderCatalogDropdownItem,
  AppHeaderItem,
  DescriptionSectionContainer,
  LayoutGridAntiAliased,
  useMegaMenuHeaderResponsiveStyles,
} from '../../shared';
import { useAppHeaderDropdownContext } from '../AppHeaderDropdownProvider';
import {
  BannerAnchor,
  StyledAnchorColumn,
  StyledColumn,
  StyledFillButton,
  SubjectAnchor,
  topicWrapperProps,
} from './elements';

export type AppHeaderCatalogSectionProps = AppHeaderAction & {
  item: AppHeaderCatalogDropdownItem;
  ref?: RefObject<HTMLUListElement>;
  role?: string;
  id?: string;
  keyDownEvents?: (event: React.KeyboardEvent) => void;
  isOpen?: boolean;
  handleClose?: () => void;
  showQuiz?: boolean;
};

const quizAnchorData: AppHeaderItem = {
  text: 'Take our quiz',
  id: 'quiz',
  type: 'text-button',
  href: '/explore/sorting-quiz',
  trackingTarget: 'sorting_quiz',
};

const onboardingQuizAnchorData: AppHeaderItem = {
  text: 'Take our quiz',
  id: 'onboarding_quiz',
  type: 'text-button',
  href: '/welcome/find-a-course',
  trackingTarget: 'sorting_quiz',
};

const catalogButtonData: AppHeaderItem = {
  text: 'Explore all courses',
  id: 'catalog',
  type: 'text-button',
  href: '/catalog',
  trackingTarget: 'topnav_catalog_explore_full',
};

const cyberHighlightData: AppHeaderItem = {
  text: 'Prepare for your Cybersecurity Certification with newly launched Skill Paths.',
  id: 'nav-highlight',
  type: 'link',
  href: '/catalog/subject/cybersecurity?type=skill-path',
  trackingTarget: 'topnav_catalog_banner_cybersecurity',
};

export const AppHeaderCatalogSection = React.forwardRef<
  HTMLDivElement,
  AppHeaderCatalogSectionProps
>(({ action, item, isOpen, keyDownEvents, handleClose, showQuiz }, ref) => {
  const firstElemRef = useRef<HTMLAnchorElement>(null);
  const { setFirstItemRef } = useAppHeaderDropdownContext();
  const showUnifiedQuiz = useContext(UnifiedRecFlowV2Context);

  const tabIndex = isOpen === false ? -1 : 0;
  const styles = useMegaMenuHeaderResponsiveStyles();

  useEffect(() => {
    if (setFirstItemRef && firstElemRef.current) {
      setFirstItemRef(firstElemRef.current);
    }
  }, [setFirstItemRef, firstElemRef]);

  const navHighlightData = cyberHighlightData;

  const topSubjects = getTopSubjects();

  const onClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    linkItem: AppHeaderItem
  ) => {
    handleClose?.();
    return action(event, linkItem);
  };

  const DescriptionSection: React.FC<{
    title: string;
    subtitle: string;
  }> = ({ title, subtitle }) => (
    <DescriptionSectionContainer
      data-focusablecatalog="true"
      data-testid="title-description-section"
      tabIndex={-1}
      flexDirection="column"
    >
      <Text as="h2" variant="title-xs" mb={8} fontWeight={700}>
        {title}
      </Text>
      <Text fontSize={14}>{subtitle}</Text>
    </DescriptionSectionContainer>
  );

  return (
    <LayoutGridAntiAliased onKeyDown={keyDownEvents} ref={ref} as="ul" p={0}>
      <StyledColumn as="li" size={12} key="Popular course topics">
        <LayoutGrid>
          <Column size={{ xs: 12, lg: 3 }} rowspan={2}>
            <Background
              {...styles}
              px={{ _: 16, xs: 32, sm: 64, md: 48, lg: 24 }}
              py={{ _: 16, sm: 32 }}
              borderBottom={1}
            >
              <FlexBox
                height="100%"
                flexDirection="column"
                justifyContent={{ lg: 'space-between' }}
              >
                <DescriptionSection
                  title="Popular course topics"
                  subtitle="Explore free or paid courses in topics that interest you."
                />
                <StyledFillButton
                  data-focusablecatalog="true"
                  href={catalogButtonData.href}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => onClick(event, item as AppHeaderItem)}
                  tabIndex={tabIndex}
                  ref={firstElemRef}
                >
                  {catalogButtonData.text}
                </StyledFillButton>
              </FlexBox>
            </Background>
          </Column>
          <Column
            size={{ xs: 12, lg: 8 }}
            py={32}
            pl={{ _: 16, xs: 32, sm: 64, md: 48 }}
          >
            <GridBox
              as="ul"
              gridTemplateColumns={{ _: '1fr', lg: 'repeat(3, 1fr)' }}
              gridTemplateRows={{ _: 'repeat(18, 1fr)', lg: 'repeat(6, 1fr)' }}
              listStyleType="none"
              gridAutoFlow="column"
              pl={0}
            >
              {topLanguages.map((item) => (
                <Box key={item.id} minHeight={32} {...topicWrapperProps}>
                  <Anchor
                    data-focusablecatalog="true"
                    variant="interface"
                    href={item.href}
                    onClick={(
                      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => onClick(event, item as AppHeaderItem)}
                    tabIndex={tabIndex}
                  >
                    {item.text}
                  </Anchor>
                </Box>
              ))}
              {topSubjects.map((item) => (
                <Box key={item.id} minHeight={36} {...topicWrapperProps}>
                  <SubjectAnchor
                    data-focusablecatalog="true"
                    variant="interface"
                    href={item.href}
                    onClick={(
                      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => onClick(event, item as AppHeaderItem)}
                    tabIndex={tabIndex}
                  >
                    {item.text}
                  </SubjectAnchor>
                </Box>
              ))}
            </GridBox>
          </Column>
          <GridBox
            bg="paleYellow"
            gridColumnStart={{ xs: 1, lg: 4 }}
            gridColumnEnd="span 12"
            py={16}
            px={{ _: 16, xs: 32, sm: 64, md: 48 }}
            borderTop={1}
          >
            <BannerAnchor
              variant="interface"
              href={navHighlightData.href}
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => onClick(event, navHighlightData as AppHeaderItem)}
              data-focusablecatalog="true"
              tabIndex={tabIndex}
            >
              <Box display={{ xs: 'flex' }} width="100%" alignItems="center">
                <Badge variant="tertiary" size="sm" mr={8}>
                  New
                </Badge>
                {navHighlightData.text}
              </Box>
              <MiniArrowRightIcon justifySelf="end" alignSelf="center" />
            </BannerAnchor>
          </GridBox>
        </LayoutGrid>
      </StyledColumn>
      {!item.hideCareerPaths && (
        <StyledColumn as="li" size={12} key="Top career paths">
          <LayoutGrid>
            <Column size={{ xs: 12, lg: 3 }}>
              <Background
                {...styles}
                px={{ _: 16, xs: 32, sm: 64, md: 48, lg: 24 }}
                py={{ _: 16, sm: 32 }}
              >
                <DescriptionSection
                  title="Top career paths"
                  subtitle="Choose your career. We'll teach you the skills to get job-ready."
                />
              </Background>
            </Column>
            <Column size={{ xs: 12, lg: 8 }}>
              <LayoutGrid
                as="ul"
                py={32}
                pl={{ _: 16, xs: 32, sm: 64, md: 48 }}
              >
                {careerPaths.map((item) => (
                  <StyledAnchorColumn
                    as="li"
                    key={item.id}
                    size={{ _: 12, lg: 4 }}
                  >
                    <Anchor
                      data-focusablecatalog="true"
                      variant="interface"
                      href={item.href}
                      onClick={(
                        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                      ) => onClick(event, item as AppHeaderItem)}
                      tabIndex={tabIndex}
                    >
                      {item.text}
                    </Anchor>
                  </StyledAnchorColumn>
                ))}
              </LayoutGrid>
            </Column>
          </LayoutGrid>
        </StyledColumn>
      )}
      {showQuiz && (
        <Column px={{ _: 16, xs: 32, sm: 64, md: 48, lg: 24 }} py={16}>
          <FlexBox
            alignItems={{ _: 'flex-start', sm: 'center' }}
            flexDirection={{ _: 'column', sm: 'row' }}
          >
            <FlexBox alignItems="center">
              <Box mr={12}>
                <TinyBlocks aria-hidden height={26} width={26} />
              </Box>
              <Box>Not sure where to begin?</Box>
            </FlexBox>
            <Box>
              <Anchor
                variant="standard"
                fontSize={14}
                fontWeight={700}
                textAlign={{ _: 'left', lg: 'center' }}
                href={
                  showUnifiedQuiz
                    ? onboardingQuizAnchorData.href
                    : quizAnchorData.href
                }
                data-focusablecatalog="true"
                onClick={(
                  event: React.MouseEvent<
                    HTMLAnchorElement | HTMLButtonElement,
                    MouseEvent
                  >
                ) =>
                  onClick(
                    event,
                    showUnifiedQuiz ? onboardingQuizAnchorData : quizAnchorData
                  )
                }
                tabIndex={tabIndex}
                ml={{ _: 0, sm: 16 }}
                pt={{ _: 8, sm: 0 }}
              >
                {showUnifiedQuiz
                  ? onboardingQuizAnchorData.text
                  : quizAnchorData.text}
                <span aria-hidden>&nbsp;→</span>
              </Anchor>
            </Box>
          </FlexBox>
        </Column>
      )}
    </LayoutGridAntiAliased>
  );
});
