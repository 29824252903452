import { useMemo } from 'react';

import { getResumePath } from './resumePathHelpers';
import {
  isJourneyResumeContent,
  isPathResumeContent,
  ResumeContainer,
} from './types';

export const useGetResumeButtonPath = (
  resumeContainer: ResumeContainer | undefined | null,
  contentItem?: { slug?: string; type?: string | null }
) =>
  useMemo(() => {
    if (!resumeContainer?.resumeContent) return '';

    const { slug: containerSlug, resumeContent } = resumeContainer;

    let resumeSlugs;
    if (isJourneyResumeContent(resumeContent)) {
      resumeSlugs = {
        journeySlug: containerSlug,
        pathSlug: resumeContent.path?.slug,
        courseSlug: resumeContent.track?.slug,
      };
    } else if (isPathResumeContent(resumeContent)) {
      resumeSlugs = {
        pathSlug: containerSlug,
        courseSlug: resumeContent.track?.slug,
      };
    } else {
      resumeSlugs = { courseSlug: containerSlug };
    }

    const resume = {
      ...resumeSlugs,
      moduleSlug: resumeContent.contentModule?.slug,
    };

    // if contentItem is not provided, use the contentItem from resumeContent (which is the resume content item of a given container)
    return getResumePath(resume, {
      slug: contentItem ? contentItem.slug : resumeContent.contentItem?.slug,
      type: contentItem ? contentItem.type : resumeContent.contentItem?.type,
    });
  }, [resumeContainer, contentItem]);
