import { FlexBox, Text } from '@codecademy/gamut';
import sample from 'lodash/sample';
import { useMemo } from 'react';

import { emptyNotificationContents } from './emptyNotificationContents';

export const EmptyNotification = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const content = useMemo(() => sample(emptyNotificationContents), [])!;

  const Image = content.image;

  return (
    <FlexBox
      as="li"
      flexDirection="column"
      alignItems="center"
      pt={32}
      pb={48}
      data-testid="empty-notification"
      aria-label="You have no notifications"
    >
      <FlexBox pb={16}>
        <Image aria-hidden width={80} height={60} />
      </FlexBox>
      <FlexBox flexDirection="column" alignItems="center">
        <Text as="p" mb={16}>
          {content.text}
        </Text>
        <Text as="p">{content.subtext}</Text>
      </FlexBox>
    </FlexBox>
  );
};
