import { useRouter } from 'next/router';

// Pages that currently have a unique experience for enterprise users
export const pages = [
  '/articles',
  '/articles/[categoryType]/[slug]',
  '/article/[[...slugs]]',
  '/learn/emodules/[moduleSlug]',
  '/learn/emodules/[moduleSlug]/cheatsheet',
  '/resources/docs',
  '/resources/docs/[topic]/[[...slugs]]',
  '/resources/docs/contribution-guide',
  '/resources/docs/contribution-guide/[slug]',
  '/404',
];

/**
 * This function checks if the current page
 * has content that has a unique experience for
 * Percipio users.
 *
 * @returns boolean
 */
export const useEnterprisePages = () => {
  const { asPath, pathname } = useRouter();
  // we want to return specifically on the path here because this is a
  // landing page that has a /pages[slug] pathname. To prevent header from showing up on all
  // landing pages
  if (asPath === '/pages/workspaces') return true;

  return pages.includes(pathname);
};
