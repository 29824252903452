export function getSearchPath(query?: string, fromPrevSearch?: string) {
  let path = '/search';
  if (query) {
    path += `?query=${encodeURIComponent(query)}`;
    if (fromPrevSearch) {
      // When users come from search-as-you-type to the search page
      // we want to link the first search_id (for inline results in the top bar)
      // to the second search_id used to get a full set of results on the search page.
      path += `&fromPrevSearch=${encodeURIComponent(fromPrevSearch)}`;
    }
  }
  return path;
}
