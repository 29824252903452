import { ReactSDKClient } from '@optimizely/react-sdk';
import { useMemo } from 'react';

import { Datafile, OptimizelyUser } from './optimizely';

export const OPTIMIZELY_PROP_NAME = '__OPTIMIZELY__';

export type OptimizelyFromServer = {
  optimizelyUser: OptimizelyUser;
  datafile: Datafile | null;
};

export type PagePropsWithOptimizely = {
  [OPTIMIZELY_PROP_NAME]?: OptimizelyFromServer;
};

export const exportOptimizelyAsProps = (optimizely?: ReactSDKClient) => {
  if (!optimizely) return;

  return {
    [OPTIMIZELY_PROP_NAME]: {
      optimizelyUser: optimizely.user,
      datafile: optimizely.getOptimizelyConfig()?.getDatafile() ?? null,
    },
  };
};

export const useOptimizelyFromServer = (pageProps: PagePropsWithOptimizely) =>
  useMemo(() => pageProps[OPTIMIZELY_PROP_NAME], [pageProps]);
