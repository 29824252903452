import { AppHeaderDropdownItem } from '../../shared';
import { AppHeaderDropdownNavButton } from './AppHeaderDropdownNavButton';
import { AppHeaderMenuNavButton } from './AppHeaderMenuNavButton';

interface AppHeaderNavButtonProps {
  buttonRef: React.RefObject<HTMLButtonElement>;
  handleOnClick: (event: React.MouseEvent) => void;
  isOpen: boolean;
  item: AppHeaderDropdownItem;
}

export const AppHeaderNavButton: React.FC<AppHeaderNavButtonProps> = ({
  buttonRef,
  handleOnClick,
  isOpen,
  item,
}) => {
  const isProfileDropdown = item.type === 'profile-dropdown';

  if (isProfileDropdown) {
    return (
      <AppHeaderMenuNavButton
        buttonRef={buttonRef}
        handleOnClick={handleOnClick}
        isOpen={isOpen}
        item={item}
      />
    );
  }
  return (
    <AppHeaderDropdownNavButton
      buttonRef={buttonRef}
      handleOnClick={handleOnClick}
      isOpen={isOpen}
      title={item.text}
    />
  );
};
