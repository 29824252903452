import { createTracker } from '@mono/tracking';
import React, { createContext, useContext, useMemo } from 'react';

type Tracker = ReturnType<typeof createTracker>;

export interface TrackingContextValue {
  trackUserClick: Tracker['click'];
  trackEvent: Tracker['event'];
  trackUserVisit: Tracker['visit'];
  trackUserImpression: Tracker['impression'];
  trackSetFilter: (filter_key: string, filter_value: filterType) => void;
  trackSearchQuery: (search_query: string) => void;
}

export type filterType = string | string[] | number | boolean;

export const TrackingContext = createContext<TrackingContextValue | undefined>(
  undefined
);

export const TrackingProvider: React.FC<{
  apiBaseUrl: string;
  verbose: boolean;
  sourceCodebase: string;
  children: React.ReactNode;
}> = ({ children, sourceCodebase: source_codebase, apiBaseUrl, verbose }) => {
  const tracker = useMemo(
    () =>
      createTracker({
        apiBaseUrl,
        verbose,
      }),
    [apiBaseUrl, verbose]
  );

  const events = useMemo<TrackingContextValue>(
    () => ({
      trackUserClick: (data) => tracker.click({ ...data, source_codebase }),
      trackUserVisit: (data) => tracker.visit({ ...data, source_codebase }),
      trackUserImpression: (data) =>
        tracker.impression({ ...data, source_codebase }),
      trackEvent: (category, event, data) =>
        tracker.event(category, event, { ...data, source_codebase }),
      trackSetFilter: (filter_key: string, filter_value: filterType) =>
        tracker.event('business', 'filter_event', { filter_key, filter_value }),
      trackSearchQuery: (search_query: string) =>
        tracker.event('business', 'search_event', { search_query }),
    }),
    [source_codebase, tracker]
  );

  return (
    <TrackingContext.Provider value={events}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within TrackingContext.Provider');
  }
  return context;
};
