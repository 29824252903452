import { Anchor } from '@codecademy/gamut';
import styled from '@emotion/styled';

interface PastDueErrorMessage {
  text1: string;
  text2: string | JSX.Element;
}

const StyledAnchor = styled(Anchor)<{ isAlert: boolean }>`
  ${({ theme, isAlert }) => (isAlert ? `color: ${theme.colors.white}` : '')};
`;

export const pastDueErrorMessages = (
  isSilverPlan: boolean,
  isAlert: boolean
): Record<string, PastDueErrorMessage> => ({
  insufficient_funds: {
    text1: `Your payment did not go through due to insufficient funds.`,
    text2: `To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your payment method.`,
  },
  declined: {
    text1: `Your payment did not go through. Contact your bank or card issuer to find out more.`,
    text2: `To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your payment method.`,
  },
  temporary_hold: {
    text1: `Your payment method was placed on a temporary hold. Contact your bank or card issuer to find out more.`,
    text2: `To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your payment method.`,
  },
  invalid_transaction: {
    text1: `Your payment did not go through. Contact your bank or card issuer to find out more about possible transaction restrictions.`,
    text2: `To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your payment method.`,
  },
  invalid_account_number: {
    text1: `Your payment did not go through because your card details may be incorrect.`,
    text2: `To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your card details or payment method.`,
  },
  expired_card: {
    text1: `Your payment did not go through because your card is expired.`,
    text2: `To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your payment method.`,
  },
  default: {
    text1: `Your payment did not go through. To keep learning with Codecademy ${
      isSilverPlan ? 'Plus' : 'Pro'
    }, please update your payment method.`,
    text2: (
      <>
        Let our{' '}
        <StyledAnchor
          href="https://help.codecademy.com/hc/en-us"
          target="_blank"
          rel="noreferrer"
          isAlert={isAlert}
        >
          customer support
        </StyledAnchor>{' '}
        team know if you have further questions.
      </>
    ),
  },
});
