import { FeatureFlag } from '@mono/data/feature-flags';
import { AnonymousUser, LoggedInUser, userIsLoggedIn } from '@mono/data/user';

import { useEnterprisePages } from './enterprisePages';

export const useEnterpriseUser = (
  user: AnonymousUser | LoggedInUser | undefined
) => {
  const enterprisePage = useEnterprisePages();

  return (
    user &&
    userIsLoggedIn(user) &&
    user?.features.includes(FeatureFlag.ENTERPRISE_GLOBAL_NAV) &&
    user?.isSkillsoftUser &&
    !user?.roles?.admin &&
    enterprisePage
  );
};
