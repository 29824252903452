import { logError } from '@mono/data/logging';
import React from 'react';

export interface ErrorBoundaryProps extends React.PropsWithChildren {
  fallback?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError?: true;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, extraInfo?: React.ErrorInfo) {
    logError(error, extraInfo);
  }

  render() {
    return this.state?.hasError
      ? this.props.fallback || null
      : this.props.children;
  }
}
