export const isOnServer = () => typeof window === 'undefined';
export const isOnBrowser = () => !isOnServer();

/**
 * Returns true if the app is running the development build
 */
export const isLocalDevBuild = () => {
  if (!process.env.NODE_ENV) return true;
  if (process.env.NODE_ENV === 'development') return true;
  return false;
};

/**
 * Returns true if the build is a production build.
 * This shouldn't be used to determine if the app is running in production.
 */
export const isProductionBuild = () => process.env.NODE_ENV === 'production';
