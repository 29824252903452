import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

import {
  AppHeaderAction,
  AppHeaderDropdownItem,
  StyledDropdown,
} from '../../shared';
import { AppHeaderDropdownProvider } from '../AppHeaderDropdownProvider';
import { AppHeaderLinkSections } from '../AppHeaderLinkSections';
import { AppHeaderMenuProvider } from '../AppHeaderMenuProvider';
import { AppHeaderNavButton } from '../AppHeaderNavButton';
import { useAppHeaderContext } from '../AppHeaderProvider';

const StyledLinkSection = styled(AppHeaderLinkSections)(
  css({
    padding: `0.75rem 0`,
    position: `absolute`,
  })
);

export type AppHeaderDropdownProps = AppHeaderAction & {
  item: AppHeaderDropdownItem;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  /**
   * If true, the dropdown is being rendered as a standalone component, rather than
   * part of the global header.
   */
  standalone?: boolean;
};

export const AppHeaderDropdown: React.FC<AppHeaderDropdownProps> = ({
  action,
  item,
  standalone,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const { lastOpenedDropdown, setLastOpenedDropdown } = useAppHeaderContext();

  const tabIndex = isOpen === false ? -1 : 0;
  const dropdownId = item?.text ?? item?.type;

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const handleOnClick = (event: React.MouseEvent) => {
    toggleIsOpen();
    if (!isOpen) {
      action(event, item);
      if (setLastOpenedDropdown) {
        setLastOpenedDropdown(dropdownId);
      }
    }
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
    buttonRef.current?.focus();
  }, []);

  useEffect(() => {
    if (lastOpenedDropdown !== dropdownId && isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOpenedDropdown]);

  useIsomorphicLayoutEffect(() => {
    if (listRef.current) {
      const { height, width } = listRef.current.getBoundingClientRect();
      setDimensions({ height, width });
    }
  }, [listRef, isOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | Event) {
      const list = listRef?.current;
      const button = buttonRef?.current;
      if (
        isOpen &&
        list &&
        !list.contains(event.target as Node) &&
        button &&
        !button.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('blur', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('blur', handleClickOutside);
    };
  }, [listRef, isOpen, handleClose]);

  const isProfileDropdown = item.type === 'profile-dropdown';
  const isGenericDropdown = item.type === 'dropdown';

  const Provider = isProfileDropdown
    ? AppHeaderMenuProvider
    : AppHeaderDropdownProvider;

  return (
    <Provider handleClose={handleClose}>
      <AppHeaderNavButton
        buttonRef={buttonRef}
        handleOnClick={handleOnClick}
        isOpen={isOpen}
        item={item}
      />
      <StyledDropdown
        style={{
          right: isProfileDropdown ? '0.5rem' : '',
          top: isProfileDropdown ? '2.75rem' : '2.25rem',
          width: dimensions.width,
        }}
        initial={{ borderWidth: 0, height: 0 }}
        animate={{
          borderWidth: isOpen ? 1 : 0,
          height: isOpen ? dimensions.height : 0,
        }}
        transition={{ duration: 0.175 }}
        aria-hidden={!isOpen}
      >
        <StyledLinkSection
          standalone={standalone}
          action={action}
          item={item}
          ref={listRef}
          id={`menu-container${item.text}`}
          showIcon={(isProfileDropdown || isGenericDropdown) ?? null}
          aria-controls={`menu-container${item.text}`}
          aria-label={item.text}
          aria-hidden={!isOpen}
          tabIndex={tabIndex}
        />
      </StyledDropdown>
    </Provider>
  );
};
