import { CourseDifficulty, EnrollmentStatus } from '@mono/brand';
import {
  EnrolledPathQuery,
  EnrolledTrackQuery,
  JourneyResumeLocationFragment,
  JourneyResumeQuery,
  PathResumeLocationFragment,
  PathResumeQuery,
  TrackResumeLocationFragment,
  TrackResumeQuery,
} from '@mono/data/codegen/client/graphql-gateway/graphql';

export type CourseData = {
  id: string;
  slug: string;
  urlPath: string;
  title: string;
  lessonCount: number;
  contentModuleIds: string[];
  grantsCertificate: boolean;
  enrollmentStatus: EnrollmentStatus;
  pro: boolean;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  type: 'course';
  rank?: number | null;
  createdAt?: string | null;
  popularity?: number | null;
};

export enum PathGoalEnum {
  Career = 'career',
  Skill = 'skill',
}

export type PathData = {
  id: string;
  slug: string;
  urlPath: string;
  imageUrl: string;
  goal: PathGoalEnum;
  title: string;
  lessonCount: number;
  courseCount: number;
  enrollmentStatus: EnrollmentStatus;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  hasCareerJourney: boolean; // career paths only
  type: 'path';
  rank?: number | null;
  createdAt?: string | null;
  popularity?: number | null;
};

export type CurriculumCardData = CourseData | PathData;

export const isCourseData = (
  content: CurriculumCardData
): content is CourseData => content.type === 'course';

export const isPathData = (content: CurriculumCardData): content is PathData =>
  content.type === 'path';

type JourneyResumeContent = NonNullable<
  JourneyResumeLocationFragment['resumeContent']
>;
type PathResumeContent = NonNullable<
  PathResumeLocationFragment['resumeContent']
>;
type TrackResumeContent = NonNullable<
  TrackResumeLocationFragment['resumeContent']
>;

export type ResumeContent =
  | JourneyResumeContent
  | PathResumeContent
  | TrackResumeContent;

export type DashboardResumeContainer =
  | NonNullable<JourneyResumeQuery['journey']>
  | NonNullable<PathResumeQuery['path']>
  | NonNullable<TrackResumeQuery['track']>;

export type SyllabusResumeContainer =
  | NonNullable<EnrolledPathQuery['path']>
  | NonNullable<EnrolledTrackQuery['track']>;

export type ResumeContainer =
  | DashboardResumeContainer
  | SyllabusResumeContainer;

export const isJourneyResumeContent = (
  resumeContent: ResumeContent
): resumeContent is JourneyResumeContent =>
  'type' in resumeContent && resumeContent.type === 'JourneyResumeLocationV2';

export const isPathResumeContent = (
  resumeContent: ResumeContent
): resumeContent is PathResumeContent =>
  'type' in resumeContent && resumeContent.type === 'PathResumeLocationV2';

export const isTrackResumeContent = (
  resumeContent: ResumeContent
): resumeContent is TrackResumeContent =>
  'type' in resumeContent && resumeContent.type === 'TrackResumeLocationV2';
