import { focusVisibleStyle } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { AppHeaderListItem } from '../../AppHeader/AppHeaderElements/AppHeaderListItem';
import {
  AppHeaderMenuProvider,
  AppHeaderMenuProviderProps,
} from '../../AppHeader/AppHeaderElements/AppHeaderMenuProvider';

const uiWrapperFocus = focusVisibleStyle('-4px');

export const MobileUlWrapper = styled.ul(
  css({
    listStyle: 'none',
    p: 0,
    m: 0,
    ...uiWrapperFocus,
  })
);

type MobileSubMenuWrapperProps =
  | Pick<AppHeaderMenuProviderProps, 'handleClose'> & { isMenuRole: boolean };

export const MobileSubMenuWrapper = ({
  children,
  handleClose,
  isMenuRole,
}: PropsWithChildren<MobileSubMenuWrapperProps>) => {
  return isMenuRole ? (
    <AppHeaderMenuProvider as={AppHeaderListItem} handleClose={handleClose}>
      {children}
    </AppHeaderMenuProvider>
  ) : (
    <AppHeaderListItem>{children}</AppHeaderListItem>
  );
};
