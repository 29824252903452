import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface AppHeaderContextType {
  lastOpenedDropdown: string | undefined;
  setLastOpenedDropdown:
    | Dispatch<SetStateAction<string | undefined>>
    | undefined;
}

export const AppHeaderContext = createContext<AppHeaderContextType>({
  lastOpenedDropdown: undefined,
  setLastOpenedDropdown: undefined,
});

export const AppHeaderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [lastOpenedDropdown, setLastOpenedDropdown] = useState<
    string | undefined
  >();

  return (
    <AppHeaderContext.Provider
      value={{ lastOpenedDropdown, setLastOpenedDropdown }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

export const useAppHeaderContext = () => useContext(AppHeaderContext);
