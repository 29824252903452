import { useEffect, useRef, useState } from 'react';

import { useIntersection } from './useIntersection';

// Set either rootMargin or threshold for your use case. The threshold
// will determine the amount of the element that needs to come into the viewport
// before the useEverInView hook is triggered. The rootMargin will determine the
// size of the root element's bounding box.
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#intersection_observer_options
export const useEverInView = (rootMargin = '0px', threshold = 0.2) => {
  const ref = useRef(null);
  const [everInView, setEverInView] = useState(false);

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin,
    threshold,
  });

  const inView = intersection?.isIntersecting;
  useEffect(() => {
    if (inView) {
      setEverInView(true);
    }
  }, [inView]);

  return { everInView: Boolean(everInView || inView), ref };
};
