import { parseDate } from '@mono/util/date';
import querystring from 'querystring';

const DRS_CACHE_TTL = 86400000; // 1 day in milliseconds

const drsRequestHeaders = {
  Authorization: `Bearer ${process.env.DRS_JWT_TOKEN}`,
  'CF-Access-Client-Id': `${process.env.DRS_CF_ACCESS_CLIENT_ID}`,
  'CF-Access-Client-Secret': `${process.env.DRS_CF_ACCESS_CLIENT_SECRET}`,
};

const createDrsJob = async (queryName: string) => {
  await fetch(`${process.env.DRS_URL}/v1/query/${queryName}/job?force=true`, {
    headers: drsRequestHeaders,
    method: 'POST',
  });
};

export type DrsData<T> = {
  created_at: string;
  query_name: string;
  data: T[];
};

export const drsQueryFetcher = async <T>(
  queryName: string
): Promise<DrsData<T>> => {
  let drsData: DrsData<T>;
  const response = await fetch(
    `${process.env.DRS_URL}/v1/query/${queryName}/data`,
    {
      headers: drsRequestHeaders,
    }
  );
  if (response.ok) {
    drsData = (await response.json()) as DrsData<T>;
  } else {
    if (response.status === 404) {
      await createDrsJob(queryName);
    }
    throw new Error(
      `Could not fetch data from the DWH Reporting Service for query ID ${queryName}. Status: ${
        response.status
      } Response: ${await response.text()}`
    );
  }
  const createdAtDate = parseDate(drsData.created_at);
  if (Date.now() - createdAtDate.valueOf() > DRS_CACHE_TTL) {
    await createDrsJob(queryName);
  }
  return drsData;
};

export type DrsRecsData<T> = {
  created_at: string;
  query_name: string; // Includes a slug cache key. NOT to be used to trigger DRS jobs.
  data: {
    ranked_recommendations: T[];
  };
};

export const drsRecsFetcher = async <T>(
  endpoint: string,
  queryParams: Record<string, string>
): Promise<DrsRecsData<T>> => {
  let drsData: DrsRecsData<T>;

  const response = await fetch(
    `${process.env.DRS_URL}/v1/query/${endpoint}?${querystring.stringify(
      queryParams
    )}`,
    {
      headers: drsRequestHeaders,
    }
  );
  if (response.ok) {
    drsData = (await response.json()) as DrsRecsData<T>;
  } else {
    if (response.status === 404) {
      // job name is the endpoint name, NOT the 'query_name' returned by drs
      await createDrsJob(endpoint);
    }
    throw new Error(
      `Could not fetch data from the DWH Reporting Service for ${endpoint} with query params ${JSON.stringify(
        queryParams
      )}. Status: ${response.status} Response: ${await response.text()}`
    );
  }
  const createdAtDate = parseDate(drsData.created_at);
  if (Date.now() - createdAtDate.valueOf() > DRS_CACHE_TTL) {
    await createDrsJob(endpoint);
  }
  return drsData;
};
