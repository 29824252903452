import { logError } from '@mono/data/logging';
import stringHash from '@sindresorhus/string-hash';
import { Cache, CacheClass } from 'memory-cache';

export type FetchCacherParams<Variables> = {
  variables?: Variables;
  logQueryInDev?: boolean;
  bustCache?: boolean;
  revalidateMs?: number;
};

type GetData<Variables, ResultData> = (
  cacheKey: string,
  params: FetchCacherParams<Variables>
) => Promise<ResultData>;

const fetchCacheBase = new Cache<string, unknown>();

export const fetchCacher = async <
  ResultData,
  Variables extends Record<string, unknown | never> = Record<string, unknown>
>(
  rawCacheKey: string,
  getData: GetData<Variables, ResultData>,
  params: FetchCacherParams<Variables> = {}
) => {
  const fetchCache = fetchCacheBase as CacheClass<string, ResultData>;

  const cacheKey = stringHash(
    `${rawCacheKey}${JSON.stringify(params.variables) ?? ''}`
  ).toString();

  if (params.bustCache) {
    fetchCache.del(cacheKey);
  }

  const cachedResult = fetchCache.get(cacheKey);

  if (cachedResult !== null && cachedResult !== undefined) {
    return cachedResult;
  }

  try {
    const fetchResult = await getData(cacheKey, params);
    fetchCache.put(cacheKey, fetchResult, params.revalidateMs);
    return fetchResult;
  } catch (err) {
    fetchCache.del(cacheKey);
    logError(err);
    throw err;
  }
};
