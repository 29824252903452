import { ContentContainer } from '@codecademy/gamut';
import { css, states } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import * as React from 'react';
import { useMemo } from 'react';

import {
  AppHeaderAction,
  AppHeaderDropdownItem,
  AppHeaderLinkItem,
  appHeaderMobileBreakpoint,
} from '../../shared';
import {
  AppHeaderDropdownLink,
  AppHeaderMenuItem,
} from '../AppHeaderDropdownLinks';

export type AppHeaderLinkSectionsProps = AppHeaderAction & {
  item: AppHeaderDropdownItem;
  ref?: React.RefObject<HTMLUListElement>;
  role?: string;
  id?: string;
  style?: {};
  showIcon?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  mobile?: boolean;
  /**
   * If true, the link is being rendered as a standalone component, rather than
   * part of the global header.
   */
  standalone?: boolean;
  tabIndex?: number;
};

type LinkComponentProps = AppHeaderAction & {
  isFirstElem?: boolean;
  isMenuItem?: boolean;
  link: AppHeaderLinkItem;
  showLineBreak?: boolean;
  showIcon?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  standalone?: boolean;
  tabIndex?: number;
};

type StyledListItemProps = {
  showLineBreak?: boolean;
};

const StyledList = styled.ul(
  css({
    listStyle: `none`,
    padding: 0,
  })
);

const StyledListItem = styled.li<StyledListItemProps>(
  states({
    showLineBreak: {
      '&:before': {
        bg: `gray-600`,
        content: `''`,
        display: `block`,
        height: `1px`,
        margin: `0.5rem 1.5rem`,
        width: `calc(100% - 3rem)`,
      },
    },
  })
);

const LinkComponent: React.FC<LinkComponentProps> = ({
  action,
  isFirstElem,
  isMenuItem,
  link,
  onKeyDown,
  showIcon = false,
  showLineBreak = false,
  standalone = false,
  tabIndex,
}) => {
  const DropdownLink = useMemo(
    () => (isMenuItem ? AppHeaderMenuItem : AppHeaderDropdownLink),
    [isMenuItem]
  );

  return (
    <StyledListItem
      showLineBreak={showLineBreak}
      role={isMenuItem ? 'presentation' : undefined}
    >
      <DropdownLink
        action={action}
        isFirstElem={!isMenuItem ? isFirstElem : undefined}
        item={link}
        mx={standalone ? 24 : { _: 0, [appHeaderMobileBreakpoint]: 24 }}
        onKeyDown={onKeyDown}
        py={16}
        role={isMenuItem ? 'menuitem' : undefined}
        showIcon={showIcon}
        tabIndex={tabIndex}
      />
    </StyledListItem>
  );
};

export const AppHeaderLinkSections = React.forwardRef<
  HTMLUListElement,
  AppHeaderLinkSectionsProps
>(
  (
    {
      action,
      item,
      showIcon = false,
      onKeyDown,
      mobile = false,
      standalone = false,
      tabIndex,
      ...props
    },
    ref
  ) => {
    const isProfileMenu = item.type === 'profile-dropdown';

    return (
      <ContentContainer size={mobile ? 'medium' : 'small'}>
        <StyledList
          role={isProfileMenu ? 'menu' : undefined}
          ref={ref}
          {...props}
        >
          {item.type === 'profile-dropdown'
            ? item.popover.map(
                (linkSection: AppHeaderLinkItem[], sectionIndex) =>
                  linkSection.map((link: AppHeaderLinkItem, linkIndex) => (
                    <LinkComponent
                      action={action}
                      key={link.id}
                      link={link}
                      showIcon={showIcon}
                      showLineBreak={sectionIndex !== 0 && linkIndex === 0}
                      tabIndex={tabIndex}
                      isMenuItem
                    />
                  ))
              )
            : item.popover.map((link: AppHeaderLinkItem, linkIndex) => (
                <LinkComponent
                  standalone={standalone}
                  onKeyDown={onKeyDown}
                  key={link.id}
                  action={action}
                  link={link}
                  showIcon={showIcon}
                  tabIndex={tabIndex}
                  isFirstElem={linkIndex === 0}
                />
              ))}
        </StyledList>
      </ContentContainer>
    );
  }
);
