import { IconButton } from '@codecademy/gamut';
import { SearchIcon } from '@codecademy/gamut-icons';
import { useEffect } from 'react';

import { useAppHeaderContext } from '../AppHeaderElements/AppHeaderProvider';
import { getPortalOrigin } from './SearchWorker/src';
import { SearchButtonProps } from './types';

let autocompleteCacheWarmed = false;

function warmAutocompleteCache() {
  if (typeof window !== 'undefined' && !autocompleteCacheWarmed) {
    autocompleteCacheWarmed = true;
    const { search } = window.location;
    const origin = getPortalOrigin();
    const apiUrl = `${origin}/api/portal/search/autocomplete-preload/${search}`;
    const headers = { 'Warm-Only': 'true' };
    fetch(apiUrl, { headers });
  }
}

export const SearchButton = ({
  isSearchVisible,
  searchButtonRef,
  setIsSearchVisible,
  toggleSearch,
}: SearchButtonProps) => {
  const { lastOpenedDropdown, setLastOpenedDropdown } = useAppHeaderContext();

  const expandedToggleSearch = () => {
    toggleSearch();
    if (!isSearchVisible && setLastOpenedDropdown) {
      setLastOpenedDropdown('search');
    }
  };

  useEffect(() => {
    if (lastOpenedDropdown !== 'search' && isSearchVisible) {
      setIsSearchVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOpenedDropdown]);

  return (
    <IconButton
      aria-expanded={isSearchVisible}
      data-testid="header-search"
      icon={SearchIcon}
      onClick={expandedToggleSearch}
      onMouseOver={warmAutocompleteCache}
      ref={searchButtonRef}
      tip="Search"
      tipProps={{ alignment: 'bottom-center', placement: 'floating' }}
      variant="interface"
    />
  );
};
