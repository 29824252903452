import { emojisplosions, EmojisplosionsHandler } from 'emojisplosion';
import { useEffect, useState } from 'react';

const konamiCodeKeys = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
];

let explosions: EmojisplosionsHandler | undefined;

export const useKonamimojisplosion = (onActivate?: () => void) => {
  const [inputs, setInputs] = useState<string[]>([]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const newState = [
        ...(inputs.length === konamiCodeKeys.length ? inputs.slice(1) : inputs),
        e.key,
      ];

      if (newState.join() === konamiCodeKeys.join()) {
        if (explosions) {
          explosions.cancel();
          explosions = undefined;
        } else {
          explosions = emojisplosions();
          onActivate?.();
        }
        setInputs([]);
      } else {
        setInputs(newState);
      }
    };

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [onActivate, inputs]);

  // clean up explosions on unmount
  useEffect(() => {
    return () => {
      explosions?.cancel();
      explosions = undefined;
    };
  }, []);
};
