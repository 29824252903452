import { Anchor, Column, FillButton } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

export const StyledColumn = styled(Column)(
  css({
    borderBottom: 1,
    borderColor: 'text',
  })
);

export const StyledAnchorColumn = styled(Column)(
  css({
    pb: 16,
    '&:last-child': {
      pb: 0,
    },
  })
);

export const StyledFillButton = styled(FillButton)(
  css({
    maxWidth: 170,
    mt: { _: 16, lg: 96 },
    textAlign: { _: 'center', md: 'left' },
  })
);

export const SubjectAnchor = styled(Anchor)(
  css({
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
  })
);

export const BannerAnchor = styled(Anchor)(
  css({
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    fontWeight: 700,
  })
);

export const topicWrapperProps = { as: 'li', width: '12rem' } as const;
