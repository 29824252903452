export const searchPlaceholder = 'What are you learning today?';

export const popularSearchTerms = [
  'Artificial Intelligence',
  'Data Science',
  'Python',
  'Web Development',
  'HTML',
  'Java',
];

export enum ContentType {
  COURSE = 'Course',
  SKILLPATH = 'Skill Path',
  CAREERPATH = 'Career Path',
  ARTICLE = 'Article',
  DOC = 'Doc',
}

export const popularContent = [
  {
    title: 'Learn Python 3',
    href: '/learn/learn-python-3',
    type: ContentType.COURSE,
    slug: 'learn-python-3',
    contentId: '6c152bd262967f8c941c9707ed636bda',
  },
  {
    title: 'Business Intelligence Data Analyst',
    href: '/learn/paths/bi-data-analyst',
    type: ContentType.CAREERPATH,
    slug: 'bi-data-analyst',
    contentId: 'd03c7391224540ef8f850f8807a2b72a',
  },
  {
    title: 'Code Foundations',
    href: '/learn/paths/code-foundations',
    type: ContentType.SKILLPATH,
    slug: 'code-foundations',
    contentId: '5b55e668646caa552f8e4d1d',
  },
  {
    title: 'What is Programming?',
    href: '/article/what-is-programming',
    type: ContentType.ARTICLE,
    slug: 'what-is-programming',
  },
  {
    title: 'HTML | Elements',
    href: '/resources/docs/html',
    type: ContentType.DOC,
    slug: 'html/concepts/elements',
  },
];
