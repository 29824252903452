import { ButtonBaseElements } from '@codecademy/gamut';
import { useRef, useState } from 'react';

import { AnimatedHeaderZone } from '../AppHeader/shared';
import { NotificationBell } from './NotificationBell';
import {
  AppHeaderNotificationSettings,
  NotificationsDropdownProps,
} from './types';

export type HeaderNotificationProps = {
  settings: AppHeaderNotificationSettings | undefined;
  Renderer: React.ComponentType<NotificationsDropdownProps>;
};

export const useHeaderNotifications = ({
  settings,
  Renderer,
}: HeaderNotificationProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const bellRef = useRef<ButtonBaseElements>(null);

  if (!settings) {
    return [null, null];
  }

  const handleClose = async () => {
    await setIsOpen(false);
    await bellRef.current?.focus();
  };

  return [
    {
      id: 'notifications',
      type: 'render-element',
      renderElement: () => (
        <NotificationBell
          bellRef={bellRef}
          isOpen={isOpen}
          notifications={settings.notifications}
          setIsOpen={setIsOpen}
        />
      ),
    },

    <AnimatedHeaderZone visible={isOpen} key="notifications-content">
      <Renderer
        actions={settings.actions}
        bellRef={bellRef}
        notifications={settings.notifications}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </AnimatedHeaderZone>,
  ] as const;
};
