import { Box } from '@codecademy/gamut';
import { useTheme } from '@emotion/react';
import * as React from 'react';
import { useCallback } from 'react';

import {
  AppHeader,
  AppHeaderItem,
  AppHeaderMobile,
  FormattedAppHeaderItems,
  FormattedMobileAppHeaderItems,
  isAppHeaderItemWithHref,
} from '..';
import {
  anonDefaultHeaderItems,
  anonDefaultMobileHeaderItems,
  anonLandingHeaderItems,
  anonLandingMobileHeaderItems,
  anonLoginHeaderItems,
  anonLoginMobileHeaderItems,
  anonSignupHeaderItems,
  anonSignupMobileHeaderItems,
  enterpriseHeaderItems,
  enterpriseMobileHeaderItems,
  freeHeaderItems,
  freeMobileHeaderItems,
  loadingHeaderItems,
  loadingMobileHeaderItems,
  proHeaderItems,
  proMobileHeaderItems,
  simpleBootcampHeaderItems,
  simpleBootcampMobileHeaderItems,
  simpleHeaderItems,
  simpleMobileHeaderItems,
  teamsHeaderItems,
  teamsMobileHeaderItems,
} from './GlobalHeaderVariants';
import {
  AnonHeader,
  EnterpriseHeader,
  FreeHeader,
  LoadingHeader,
  ProHeader,
  SimpleBootcampHeader,
  SimpleHeader,
  TeamsHeader,
} from './types';

export * from './types';

export type GlobalHeaderProps =
  | AnonHeader
  | FreeHeader
  | ProHeader
  | EnterpriseHeader
  | TeamsHeader
  | LoadingHeader
  | SimpleHeader
  | SimpleBootcampHeader;

// Overloading getAppHeaderItems function to return different types based on mobile parameter
function getAppHeaderItems(
  props: GlobalHeaderProps,
  mobile: false
): FormattedAppHeaderItems;

function getAppHeaderItems(
  props: GlobalHeaderProps,
  mobile: true
): FormattedMobileAppHeaderItems;

function getAppHeaderItems(
  props: GlobalHeaderProps,
  mobile: Boolean
): FormattedAppHeaderItems | FormattedMobileAppHeaderItems {
  const { hidePricing, teamsRevampEnabled } = props;
  switch (props.type) {
    case 'anon':
      switch (props.variant) {
        case 'landing':
          return mobile
            ? anonLandingMobileHeaderItems(hidePricing, props.user)
            : anonLandingHeaderItems(hidePricing, props.user);
        case 'login':
          return mobile
            ? anonLoginMobileHeaderItems(hidePricing, props.user)
            : anonLoginHeaderItems(hidePricing, props.user);
        case 'signup':
          return mobile
            ? anonSignupMobileHeaderItems(hidePricing, props.user)
            : anonSignupHeaderItems(hidePricing, props.user);
        default:
          return mobile
            ? anonDefaultMobileHeaderItems(hidePricing, props.user)
            : anonDefaultHeaderItems(hidePricing, props.user);
      }
    case 'enterprise':
      return mobile
        ? enterpriseMobileHeaderItems(props.user, props)
        : enterpriseHeaderItems(props.user, props);
    case 'free':
      const showMoneyback =
        props.isInMoneyBackGuaranteeVariant && props.user.showMoneybackCTA;
      return mobile
        ? freeMobileHeaderItems(props.user, hidePricing, showMoneyback)
        : freeHeaderItems(props.user, hidePricing, showMoneyback);
    case 'pro':
      return mobile
        ? proMobileHeaderItems(props.user)
        : proHeaderItems(props.user);
    case 'teams':
      return mobile
        ? teamsMobileHeaderItems(props.user, teamsRevampEnabled)
        : teamsHeaderItems(props.user, teamsRevampEnabled);
    case 'simple':
      return mobile ? simpleMobileHeaderItems : simpleHeaderItems;
    case 'bootcamp':
      return mobile
        ? simpleBootcampMobileHeaderItems(props.bootcampSlug)
        : simpleBootcampHeaderItems(props.bootcampSlug);
    case 'loading':
      return mobile ? loadingMobileHeaderItems : loadingHeaderItems;
  }
}

export const TeamsRevampContext = React.createContext<boolean>(false);

export const UnifiedRecFlowV2Context = React.createContext<boolean>(false);

export const GlobalHeader: React.FC<GlobalHeaderProps> = (props) => {
  const { action, onLinkAction } = props;
  const theme = useTheme();

  const combinedAction = useCallback(
    (event: React.MouseEvent, item: AppHeaderItem) => {
      action(event, item);
      if (isAppHeaderItemWithHref(item)) onLinkAction?.(event, item);
    },
    [action, onLinkAction]
  );

  const hideNotification =
    props.type === 'loading' ||
    props.type === 'enterprise' ||
    props.type === 'simple' ||
    props.type === 'bootcamp';

  const showQuiz =
    props.type === 'anon' || props.type === 'free' || props.type === 'pro';

  const hasTwoOrMoreEnrollments =
    (props.type === 'free' || props.type === 'pro') &&
    props.user.enrolledInTwoOrMoreContainers;

  const showUnifiedQuiz =
    !!props.unifiedRecFlowV2Enabled && !hasTwoOrMoreEnrollments;

  return (
    <TeamsRevampContext.Provider value={!!props.teamsRevampEnabled}>
      <UnifiedRecFlowV2Context.Provider value={showUnifiedQuiz}>
        <Box
          as="header"
          position="sticky"
          top={0}
          zIndex={theme.elements.headerZ}
        >
          <AppHeader
            action={combinedAction}
            items={getAppHeaderItems(props, false)}
            search={props.search}
            {...(props.type === 'anon'
              ? {
                  redirectParam: props.redirectParam,
                }
              : hideNotification
              ? {}
              : {
                  notifications: props.notifications,
                })}
            hideRightButtonDefaults={hideNotification}
            isAnon={props.type === 'anon'}
            isTeams={props.type === 'teams'}
            showQuiz={showQuiz}
          />
          <AppHeaderMobile
            action={combinedAction}
            items={getAppHeaderItems(props, true)}
            {...(props.type === 'anon' || hideNotification
              ? {}
              : {
                  notifications: props.notifications,
                })}
            search={props.search}
            redirectParam={
              props.type === 'anon' ? props.redirectParam : undefined
            }
            isEnterprise={props.type === 'enterprise'}
            isAnon={props.type === 'anon'}
            isSimple={props.type === 'simple'}
            hideRightMenuButton={
              props.type === 'simple' || props.type === 'loading'
            }
            navigationMenuFormattedLabel={
              props?.localizedLabels?.navigationMenuFormattedLabel
            }
            showQuiz={showQuiz}
          />
          {props.children}
        </Box>
      </UnifiedRecFlowV2Context.Provider>
    </TeamsRevampContext.Provider>
  );
};
