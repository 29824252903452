export const checkoutStatusKey = 'checkoutSuccessType';
const checkoutPlanIdKey = 'checkoutPlanId';
const checkoutProviderId = 'checkoutProviderId';
const checkoutTrialLengthKey = 'checkoutTrialLength';
const checkoutTrialUnitKey = 'checkoutTrialUnit';
const checkoutTermKey = 'checkoutPlanTermMonths';
const checkoutCurrencyKey = 'checkoutCurrency';
const checkoutSubtotalKey = 'checkoutSubtotal';
const checkoutCouponCodeKey = 'checkoutCouponCode';

export const displayName = (isPlusUser: boolean) => {
  return isPlusUser ? 'Plus' : 'Pro';
};
export const trialCopy = (
  isPlusUser: boolean,
  trialInfo: { length: number; unit: string }
) =>
  `Your ${trialInfo.length}-${
    trialInfo.unit
  } free trial of Codecademy ${displayName(isPlusUser)} starts now.`;

export const subscriptionCopy = (isSilverPlan: boolean) =>
  `Your Codecademy ${displayName(isSilverPlan)} subscription starts now.`;

export const emailCopy = 'A receipt was sent to';

export const setSessionStorageItems = (
  queryParams: Record<string, string | undefined>
) => {
  sessionStorage.setItem(
    checkoutStatusKey,
    queryParams.checkoutSuccessType || ''
  );
  sessionStorage.setItem(checkoutPlanIdKey, queryParams?.checkoutPlanId || '');
  sessionStorage.setItem(
    checkoutProviderId,
    queryParams?.checkoutProviderId || ''
  );
  sessionStorage.setItem(
    checkoutTermKey,
    queryParams?.checkoutPlanTermMonths || ''
  );
  sessionStorage.setItem(
    checkoutCurrencyKey,
    queryParams?.checkoutCurrency || ''
  );
  sessionStorage.setItem(
    checkoutSubtotalKey,
    queryParams?.checkoutSubtotal || ''
  );
  sessionStorage.setItem(
    checkoutTrialLengthKey,
    queryParams?.checkoutTrialLength || ''
  );
  sessionStorage.setItem(
    checkoutTrialUnitKey,
    queryParams?.checkoutTrialUnit || ''
  );
  sessionStorage.setItem(
    checkoutCouponCodeKey,
    queryParams?.checkoutCouponCode || ''
  );
};

export const getSessionStorageItems = () => {
  const planId = sessionStorage.getItem(checkoutPlanIdKey);
  const providerId = sessionStorage.getItem(checkoutProviderId);
  const trialLength = sessionStorage.getItem(checkoutTrialLengthKey) || 7;
  const trialUnit = sessionStorage.getItem(checkoutTrialUnitKey) || 'day';
  const termMonths = sessionStorage.getItem(checkoutTermKey);
  const currency = sessionStorage.getItem(checkoutCurrencyKey);
  const subtotal = sessionStorage.getItem(checkoutSubtotalKey);
  const couponCode = sessionStorage.getItem(checkoutCouponCodeKey);

  return {
    planId,
    providerId,
    trialLength,
    trialUnit,
    termMonths,
    currency,
    subtotal,
    couponCode,
  };
};

export const removeSessionStorageItems = () => {
  sessionStorage.removeItem(checkoutStatusKey);
  sessionStorage.removeItem(checkoutPlanIdKey);
  sessionStorage.removeItem(checkoutProviderId);
  sessionStorage.removeItem(checkoutTermKey);
  sessionStorage.removeItem(checkoutCurrencyKey);
  sessionStorage.removeItem(checkoutSubtotalKey);
  sessionStorage.removeItem(checkoutTrialLengthKey);
  sessionStorage.removeItem(checkoutTrialUnitKey);
  sessionStorage.removeItem(checkoutCouponCodeKey);
};
