import { PopoverProps } from '@codecademy/gamut';
import { RefCallback, RefObject, useCallback, useState } from 'react';
import { useWindowScroll, useWindowSize } from 'react-use';

// space between target and edge of window
type Clearance = {
  above: number;
  below: number;
};

const getClearance = (targetEl: HTMLElement, winHeight: number): Clearance => {
  const { top, bottom } = targetEl.getBoundingClientRect();
  return {
    above: top,
    below: winHeight - bottom,
  };
};

const getClearanceNeeded = (
  popoverContainerEl: HTMLElement,
  verticalOffset: number
): number => {
  const { height } = popoverContainerEl.getBoundingClientRect();
  return verticalOffset + height;
};

type Position = Required<PopoverProps>['position'];

export type UseDynamicPopoverPositionProps = {
  targetRef: RefObject<HTMLElement>;
  verticalOffset: number;
  defaultPosition: Position;
};

export type UseDynamicPopoverPositionReturn = {
  position: Position;
  popoverContainerRefCallback: RefCallback<HTMLElement | null>;
};

export const useDynamicPopoverPosition = ({
  targetRef,
  verticalOffset,
  defaultPosition,
}: UseDynamicPopoverPositionProps): UseDynamicPopoverPositionReturn => {
  const [popoverContainerEl, setPopoverContainerEl] =
    useState<HTMLElement | null>(null);

  // called when the popover container is renderd or removed
  const popoverContainerRefCallback: RefCallback<HTMLElement | null> =
    useCallback((instance: HTMLElement | null) => {
      setPopoverContainerEl(instance);
    }, []);

  const { height: winHeight } = useWindowSize(); // re-calc on window resize
  useWindowScroll(); // re-calc on scroll

  if (targetRef?.current && popoverContainerEl) {
    const clearance = getClearance(targetRef.current, winHeight);
    const clearanceNeeded = getClearanceNeeded(
      popoverContainerEl,
      verticalOffset
    );
    const clearedAbove = clearance.above >= clearanceNeeded;
    const clearedBelow = clearance.below >= clearanceNeeded;
    const verticallyCentered = clearance.above === clearance.below;

    if (!verticallyCentered && (!clearedAbove || !clearedBelow)) {
      return {
        position: clearance.above > clearance.below ? 'above' : 'below',
        popoverContainerRefCallback,
      };
    }
  }

  return {
    position: defaultPosition,
    popoverContainerRefCallback,
  };
};
