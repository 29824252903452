import { usePrefersDarkMode } from '@codecademy/gamut-styles';
import { GlobalPageBackgroundColor } from '@mono/brand';

import { useAdmin, useUserAppearance } from '.';

enum ColorModes {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system',
}

type BgColorProps =
  | {
      dark?: GlobalPageBackgroundColor;
      light?: GlobalPageBackgroundColor;
    }
  | undefined;

export const useDarkModeColor = (
  obj: BgColorProps = {
    dark: 'navy',
    light: 'white',
  }
): GlobalPageBackgroundColor => {
  const isAdmin = useAdmin();
  const prefersDarkMode = usePrefersDarkMode();
  const appearanceSettings = useUserAppearance();
  const { dark = 'navy', light = 'white' } = obj;

  if (!isAdmin) return light;
  switch (appearanceSettings?.color_mode) {
    case ColorModes.SYSTEM:
      return prefersDarkMode ? dark : light;
    case ColorModes.DARK:
      return dark;
    case ColorModes.LIGHT:
    default:
      return light;
  }
};

export const usePaleBlueBg = () => useDarkModeColor({ light: 'paleBlue' });
