import { Experiments } from '@mono/data/optimizely';
import { useMemo } from 'react';

export const EXPERIMENTS_PROP_NAME = '__EXPERIMENTS__';

export type PagePropsWithExperiments = {
  [EXPERIMENTS_PROP_NAME]?: Experiments;
};

export const exportExperimentsAsProps = (experiments?: Experiments) => ({
  [EXPERIMENTS_PROP_NAME]: experiments,
});

// NOTE: This should only be used in App.tsx
export const useExperimentsFromServer = (
  pageProps: PagePropsWithExperiments
): Experiments => {
  return useMemo(() => pageProps[EXPERIMENTS_PROP_NAME] ?? {}, [pageProps]);
};
