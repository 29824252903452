export const scrollToTarget = (scrollTarget: HTMLElement) => {
  // We want to add (and keep) tabIndex -1 so screenreader will focus on the new location
  // and user knows where they've landed.
  scrollTarget.tabIndex = -1;

  // Move focus to the scroll target so keyboard navigation skips any controls in between.
  scrollTarget.focus({ preventScroll: true });

  // scroll (or jump to if user prefers reduced motion)
  scrollTarget.scrollIntoView({
    behavior: window.matchMedia('(prefers-reduced-motion: reduce)').matches
      ? undefined
      : 'smooth',
  });
};
