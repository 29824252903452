import { useIsMounted } from '@codecademy/gamut';
import { ApolloClientService } from '@mono/apollo';
import { BannerProps } from '@mono/brand';
import { useBrandedBannerQuery } from '@mono/data/codegen/contentful';
import { useBrandedBannerUserQuery } from '@mono/data/codegen/monolith';
import { useCookies } from 'react-cookie';

import { trackUserClick } from '~/libs/tracking';

import { useLocaleContext } from '../../../libs/locale/localeContext';
import { shouldShowForUser } from './libs/shouldShowForUser';
import { getBrandedBannerCookieId, isNonNullBanner } from './types';

export const label = 'branded-banner';
const cacheOnlyPolicy = { fetchPolicy: 'cache-only' } as const;

export const useBrandedBanner = (): BannerProps | undefined => {
  // By using the default caching policy of 'cache-first', we still get performance boost if expected user data is in cache (see fetchAllBrandedBannerDataToCache func). If however that data is absent, we can allow the client-side code to re-request it from monolith.
  // See this PR for context: https://github.com/codecademy-engineering/mono/pull/4213
  const { data: userData } = useBrandedBannerUserQuery();

  // Looks like we may be using a cache-only policy (and pre-warming apollo cache as part of the getServerSidePropsWrapper) in order to avoid making calls to contentful for pages that don't have banners.
  // Ensure your page has the brandedBanner
  // option set on the getServerSideProps wrapper and it'll make all the fetches we need to render the banner.
  const { data: bannerData } = useBrandedBannerQuery({
    ...cacheOnlyPolicy,
    context: { service: ApolloClientService.Contentful },
    variables: { locale: useLocaleContext() },
  });

  const isMounted = useIsMounted();

  const [cookies, setCookie] = useCookies();

  const bannerIdToCookieValue =
    bannerData?.brandedBannerCollection?.items?.reduce<Record<string, boolean>>(
      (idToCookie, element) => {
        if (element) {
          const { id } = element.sys;
          if (id) idToCookie[id] = !!cookies[getBrandedBannerCookieId(id)];
        }
        return idToCookie;
      },
      {}
    ) ?? {};

  const bannerToShow =
    bannerData &&
    userData &&
    bannerData.brandedBannerCollection?.items.find(
      (banner) =>
        isNonNullBanner(banner) &&
        !!banner.audiences &&
        shouldShowForUser(banner.audiences, userData.me) &&
        !bannerIdToCookieValue[banner.sys.id]
    );

  if (!bannerToShow || !bannerToShow.markdownContent || !isMounted) {
    return undefined;
  }

  const {
    backgroundColor,
    markdownContent,
    sys: { id },
  } = bannerToShow;

  return {
    variant: backgroundColor as BannerProps['variant'],
    id: label,
    role: 'complementary',
    'aria-label': label,
    onClose: () => {
      setCookie(getBrandedBannerCookieId(bannerToShow.sys.id), true, {
        path: '/',
      });
      trackUserClick({
        target: 'branded_banner_closed',
        context: id,
      });
    },
    onCtaClick: () =>
      trackUserClick({
        target: 'branded_banner_cta',
        context: id,
      }),
    children: markdownContent,
  };
};
