import { Box, focusVisibleStyle } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

export const NotificationsWrapper = styled(Box)(
  css({
    display: 'flex',
    textAlign: 'left',
    width: 400,
  })
);

const focusStyle = focusVisibleStyle();

export const NotificationDialog = styled(Box)(
  css({
    pb: 24,
    pt: 32,
    width: '100%',
    ...focusStyle,
  })
);
