import { TextButton } from '@codecademy/gamut';
import { MouseEvent } from 'react';

import { Avatar } from '../../../Avatar';
import { AppHeaderProfileDropdownItem } from '../../shared';
import { useAppHeaderMenuContext } from '../AppHeaderMenuProvider';

interface AppHeaderMenuNavButtonProps {
  buttonRef: React.RefObject<HTMLButtonElement>;
  handleOnClick: (event: React.MouseEvent) => void;
  isOpen: boolean;
  item: AppHeaderProfileDropdownItem;
}

export const AppHeaderMenuNavButton: React.FC<AppHeaderMenuNavButtonProps> = ({
  buttonRef,
  handleOnClick,
  isOpen,
  item,
}) => {
  const { menuItems, resetIndex } = useAppHeaderMenuContext();

  const profileOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const menuItemsArray = menuItems.values();
    const firstMenuItem = menuItemsArray.next().value;
    handleOnClick(e);
    firstMenuItem?.focus();
  };

  return (
    <TextButton
      aria-expanded={isOpen}
      aria-haspopup
      data-testid="avatar-dropdown-button"
      onClick={profileOnClick}
      // this resets our programatic menu index to 0 in case someone clicks out of the dropdown
      onFocus={resetIndex}
      ref={buttonRef}
      variant="interface"
    >
      <Avatar
        alt="User profile avatar"
        disableDropshadow
        size={40}
        src={item.avatar}
      />
    </TextButton>
  );
};
