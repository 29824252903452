import { IncomingMessage } from 'http';

export const DEFAULT_LOCALE = 'en-US';

export function getCountryCodeFromReq(req: IncomingMessage) {
  return req.headers['cf-ipcountry'];
}

export function getContentfulLocaleFromReq(req: IncomingMessage) {
  const countryCode = getCountryCodeFromReq(req);
  if (countryCode?.includes('IN')) return 'en-IN';
  return DEFAULT_LOCALE;
}
