import { trackUserClick, trackUserImpression } from '../../../libs/tracking';

export const trackCheckoutSuccessModalImpression = (pageName: string) => {
  const target = 'checkout_confirmation_modal';
  trackUserImpression({
    page_name: pageName,
    target,
  });
  if (window.Sprig) {
    window.Sprig.track(target);
  }
};

export const trackCheckoutSuccessModalClose = (pageName: string) => {
  const target = 'checkout_confirmation_modal_close';
  trackUserClick({
    page_name: pageName,
    target,
  });
  if (window.Sprig) {
    window.Sprig.track(target);
  }
};
