import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { isOnServer, isProductionBuild } from '@mono/util/env';

import { logError } from './logging';

export interface DatadogRumConfig extends RumInitConfiguration {
  applicationId: string;
  clientToken: string;
  version: string;
  env: string;
  service: string;
}

const ignoredErrors = [
  // Errors from monaco editor that fire on scroll but don't affect the user
  'Unable to preventDefault inside passive event listener due to target being treated as passive',
  // @TODO WEB-2075 Next.js Upgrade causing new console errors when using next/link to route outside of the Next.js App https://codecademy.atlassian.net/browse/WEB-2075
  'Error: Failed to lookup route:',
];

export const startRUMTracing = (rumConfig: DatadogRumConfig) => {
  const { applicationId, clientToken, version, env, service, ...config } =
    rumConfig;
  const isProduction = env === 'production';
  if (
    isProductionBuild() &&
    ['development', 'staging', 'production'].includes(env)
  ) {
    datadogRum.init({
      applicationId,
      clientToken,
      version,
      env,
      service,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      enableExperimentalFeatures: ['feature_flags'],
      sessionSampleRate: isProduction ? 50 : 100, // 50% of all production sessions
      sessionReplaySampleRate: isProduction ? 25 : 10, // 25% of production sessions captured (12.5% of all sessions)
      startSessionReplayRecordingManually: true, // if !isOnServer(), see below
      allowedTracingUrls: [
        (url: string) => {
          if (URL.canParse(url)) {
            const { origin } = new URL(url);
            // 3rd party integration that can't handle the tracing headers
            if (origin === 'https://refer.codecademy.com') return false;
            if (origin.endsWith('.codecademy.com')) return true;
            // When this is enabled, services that we query need
            // need to have their cors configured to allow these headers:
            /*
              "X-Datadog-Origin",
              "X-Datadog-Trace-Id",
              "X-Datadog-Parent-Id",
              "X-Datadog-Sampling-Priority",
              "traceparent",
              "tracestate",
            */
          }
          return false;
        },
      ],
      beforeSend: (rumEvent) => {
        if (rumEvent.type === 'error') {
          const stack = rumEvent.error?.stack;
          if (stack && ignoredErrors.some((error) => stack.includes(error)))
            return false;
          // We still want to track these errors in dev/staging
          if (stack?.includes('Minified React error') && env === 'production')
            return false;
          // if the error contains [OPTIMIZELY], we want to alter the error message to remove the date/time stamp
          if (rumEvent.error.message.match(/\[OPTIMIZELY\]/)) {
            const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z/;
            rumEvent.error.message = rumEvent.error.message.replace(
              dateRegex,
              ''
            );
          }
        }
        // pass through the unchanged RUM Event
        return true;
      },
      ...config,
    });
  }

  if (!isOnServer()) {
    datadogRum.startSessionReplayRecording();
  }
};

export const addFeatureFlagsToRUM = (
  featureFlags: string | string[] | Record<string, unknown> | undefined,
  prefix = ''
) => {
  try {
    if (!featureFlags) return;

    if (typeof featureFlags === 'string') {
      datadogRum.addFeatureFlagEvaluation(prefix + featureFlags, true);
    } else if (Array.isArray(featureFlags)) {
      for (const flag of featureFlags) {
        datadogRum.addFeatureFlagEvaluation(prefix + flag, true);
      }
    } else {
      for (const flag of Object.keys(featureFlags)) {
        datadogRum.addFeatureFlagEvaluation(prefix + flag, featureFlags[flag]);
      }
    }
  } catch (error) {
    logError(error);
  }
};

export const addExperimentToRUM = (
  experiments: string | string[] | Record<string, unknown> | undefined
) => {
  addFeatureFlagsToRUM(experiments, 'exp_');
};
