import { getSearchWorkerSrc } from './src';

export type HighlightSegment = {
  value: string;
  highlight: boolean;
};

export type AutocompleteSuggestion = {
  title: string;
  segments: HighlightSegment[];
  key: string;
};

export type SearchAsYouTypeResults = {
  query: string;
  top: {
    type: string;
    title: string;
    slug: string;
    contentId?: string;
    urlPath: string;
    key: string;
    segments: HighlightSegment[];
  }[];
  queryLoadTime: number;
  searchId: string;
  resultsCount: number;
};

class PromiseResolverPair<T> {
  promise: Promise<T>;
  resolver!: (t: T) => void;

  constructor() {
    this.promise = new Promise<T>((r) => {
      this.resolver = r;
    });
  }
}

// prevent server "Worker is not defined" error
const Worker =
  globalThis.Worker ??
  class {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMessage() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    postMessage() {}
  };

export class SearchWorker extends Worker {
  constructor() {
    const src = getSearchWorkerSrc();
    const blob = new Blob([src], { type: 'text/javascript' });
    super(URL.createObjectURL?.(blob));
    this.onmessage = ({ data }) => {
      const { query, topAutocompleteTitles, searchAsYouTypeResults } = data;
      if (topAutocompleteTitles) {
        this.promises.autocomplete.get(query)?.resolver(topAutocompleteTitles);
      }
      if (searchAsYouTypeResults) {
        this.promises.searchAsYouType
          .get(query)
          ?.resolver(searchAsYouTypeResults);
      }
    };
  }

  private promises = {
    autocomplete: new Map<
      string,
      PromiseResolverPair<AutocompleteSuggestion[]>
    >(),
    searchAsYouType: new Map<
      string,
      PromiseResolverPair<SearchAsYouTypeResults>
    >(),
  };

  autocomplete(query: string, max: number) {
    const q = query.trim().toLowerCase();
    let p = this.promises.autocomplete.get(q);
    if (!p) {
      p = new PromiseResolverPair<AutocompleteSuggestion[]>();
      this.promises.autocomplete.set(q, p);
      this.postMessage({ action: 'autocomplete', query: q, max });
    }
    return p.promise;
  }

  searchAsYouType(query: string, max: number) {
    const q = query.trim().toLowerCase();
    let p = this.promises.searchAsYouType.get(q);
    if (!p) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      p = new PromiseResolverPair<any>();
      this.promises.searchAsYouType.set(q, p);
      this.postMessage({ action: 'search-as-you-type', query: q, max });
    }
    return p.promise;
  }
}
