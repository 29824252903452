import { GetServerSidePropsContext } from 'next';

import { getContentfulLocaleFromReq } from '~/libs/getCountryCodeFromReq';

export const LOCALE_PROP_NAME = '__LOCALE__';

export type PagePropsWithLocale = {
  [LOCALE_PROP_NAME]: {
    locale: string;
  };
};

export const exportLocaleAsProps = (
  context: GetServerSidePropsContext
): PagePropsWithLocale => ({
  [LOCALE_PROP_NAME]: {
    locale: getContentfulLocaleFromReq(context.req),
  },
});

export const useServerLocale = (pageProps: PagePropsWithLocale) =>
  pageProps?.[LOCALE_PROP_NAME]?.locale;
