import { logError } from '@mono/data/logging';
import { parse, parseISO } from 'date-fns';

export const parseDate = (dateString: string) => {
  let date = parseISO(dateString);

  // As a backup, try parsing this specific format since our database likes to use it:
  // e.g. '2021-01-12 16:33:07 -0500'
  if (!isValidDate(date))
    date = parse(dateString, 'yyyy-MM-dd HH:mm:SS X', new Date());

  if (!isValidDate(date)) date = new Date(dateString);

  if (!isValidDate(date)) {
    const errMessage = 'Could not parse date string: ' + dateString;
    logError(errMessage);
    throw new Error(errMessage);
  }

  return date;
};

const isValidDate = (date: Date) => {
  /* An invalid date object returns NaN for getTime() and NaN is the only
  object not strictly equal to itself. */

  // eslint-disable-next-line no-self-compare
  return date.getTime() === date.getTime();
};
