import { SubmitButton, useConnectedForm } from '@codecademy/gamut';
import React, { useState } from 'react';

import { ReferralFields } from './ReferralFields';

const createMember = (firstName: string, email: string) => {
  return fetch(
    `${process.env.NEXT_PUBLIC_MONOLITH ?? ''}/referralrock/members`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'CF-Access-Client-Id':
          process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID ?? '',
        'CF-Access-Client-Secret':
          process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET ?? '',
      },
      body: JSON.stringify({ firstName, email }),
    }
  );
};

export const ReferralForm: React.FC<{
  setReferralUrl: React.Dispatch<React.SetStateAction<string>>;
}> = ({ setReferralUrl }) => {
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleWatch = (values: string[]) => {
    const [firstName, email] = values;
    return firstName && email
      ? setDisableSubmit(false)
      : setDisableSubmit(true);
  };

  const { ConnectedForm, connectedFormProps } = useConnectedForm({
    defaultValues: {
      email: '',
      firstName: '',
    },
    validationRules: {
      firstName: { required: 'Please enter your first name.' },
      email: {
        required: 'Please enter your email address.',
        pattern: {
          value: /^[^@\s]+@[^@\s\.]+\.[^@\s]{2,}$/, // eslint-disable-line no-useless-escape
          message: 'Please enter a valid email address.',
        },
      },
    },
    watchedFields: {
      fields: ['firstName', 'email'],
      watchHandler: handleWatch,
    },
  });

  return (
    <ConnectedForm
      onSubmit={async ({ firstName, email }) => {
        const res = await createMember(firstName, email);
        const json = await res.json();
        setReferralUrl(json?.member?.referralUrl);
      }}
      validation="onBlur"
      {...connectedFormProps}
    >
      <ReferralFields />
      <SubmitButton
        type="submit"
        variant="primary"
        id="get-referral-link"
        width="100%"
        disabled={disableSubmit}
      >
        Get referral link
      </SubmitButton>
    </ConnectedForm>
  );
};
