import {
  BaseMeta,
  GlobalMeta,
  useGlobalHead,
  useGlobalMeta,
} from '@mono/util/dom';

export type GlobalPortalMeta = BaseMeta & GlobalMeta;

export const usePortalMeta = (props: GlobalPortalMeta) => {
  const head = useGlobalHead({ canonicalPath: props.canonicalPath });
  const meta = useGlobalMeta(props);

  return (
    <>
      {head}
      {meta}
    </>
  );
};
