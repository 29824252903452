import { NotificationResponse } from '@mono/data/user';
import { formatDistanceToNow } from 'date-fns';

export const parseNotificationResponse = (
  notification: NotificationResponse,
  iconColor: string
) => ({
  date: formatDistanceToNow(new Date(notification.time)),
  id: notification.id,
  iconSettings: { color: iconColor },
  link: notification.href,
  text: notification.description,
  unread: notification.status === 1,
  campaign: notification.campaign_name,
  imageUrl: notification.img?.type === 'url' ? notification.img.slug : '',
  type: notification.type,
});
