import { Box } from '@codecademy/gamut';
import { ErrorContents } from '@mono/brand';
import { ErrorProps } from 'next/error';

import { PortalPage } from '../PortalPage';

export const ErrorPage: React.FC<Partial<ErrorProps>> = ({ statusCode }) => {
  return (
    <PortalPage
      meta={{
        description: 'Something has gone wrong.',
        title: 'error',
      }}
      tracking={{ page_name: 'error' }}
    >
      <Box data-testid="error-page" />
      <ErrorContents
        supportInformation={
          statusCode ? [['Status code', `${statusCode}`]] : undefined
        }
      />
    </PortalPage>
  );
};
