import { Box, IconButton, Markdown, TextButton } from '@codecademy/gamut';
import { MiniDeleteIcon } from '@codecademy/gamut-icons';
import { Background, BackgroundProps, css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { ReactNode, useMemo } from 'react';
import * as React from 'react';

export type BannerVariant = keyof typeof bannerVariants;
const bannerVariants = {
  navy: 'navy',
  yellow: 'yellow',
  subtle: 'background-hover',
} as const;

const isAllowedVariant = (
  variant: string | undefined | null
): variant is BannerVariant =>
  Object.keys(bannerVariants).includes(variant as BannerVariant);

export interface BannerProps extends Omit<BackgroundProps, 'bg'> {
  children: ReactNode | string;
  /** Visual variation for banners, defaults to navy */
  variant?: BannerVariant | null;
  /**  Callback called when the user closes the banner. */
  onClose?: () => void;
  /** Call to action click callback */
  onCtaClick?: () => void;
  ctaAriaLabel?: string;
}

const bannerStyles = {
  width: '100%',
  p: 4,
  display: 'grid',
  gridTemplateColumns: '2rem minmax(0, 1fr) 2rem',
  gridTemplateAreas: "'empty content close'",
  columnGap: 8,
  alignItems: 'center',
  textAlign: 'center',
} as const;

const BannerContainerBackground = styled(Background)(css(bannerStyles));

const BannerContainerBox = styled(Box)(css(bannerStyles));

const BannerMarkdown = styled(Markdown)(css({ fontSize: 'inherit' }));

const bindBannerAnchor = (
  onCtaClick?: BannerProps['onCtaClick'],
  ctaAriaLabel?: string
) => ({
  allowedAttributes: ['href', 'target'],
  component: TextButton,
  processNode: (_node: unknown, props: { onClick?: () => void }) => (
    <TextButton
      {...props}
      onClick={() => {
        onCtaClick?.();
        props?.onClick?.();
      }}
      mx={4}
      size="small"
      target="_BLANK"
      aria-label={ctaAriaLabel}
      display={{ _: 'block', xs: 'inline-flex' }}
    />
  ),
});

export const Banner: React.FC<BannerProps> = ({
  children,
  ctaAriaLabel,
  variant = 'navy',
  onCtaClick,
  onClose,
  ...rest
}: BannerProps) => {
  const overrides = useMemo(
    () => ({
      a: bindBannerAnchor(onCtaClick, ctaAriaLabel),
    }),
    [onCtaClick, ctaAriaLabel]
  );

  // Contentful is unable to programmatically communicate what values it does/doesn't allow in these kinds of fields,
  // which makes it difficult for us to ensure the Contentful configuration hasn't diverged from Gamut restrictions.
  if (!isAllowedVariant(variant)) variant = 'navy';

  const BannerContainer =
    variant === 'subtle' ? BannerContainerBox : BannerContainerBackground;
  return (
    <BannerContainer {...rest} bg={bannerVariants[variant]}>
      <Box gridArea="content" fontSize="inherit">
        {typeof children === 'string' ? (
          <BannerMarkdown
            overrides={overrides}
            text={children}
            skipDefaultOverrides={{ a: true }}
          />
        ) : (
          children
        )}
      </Box>
      {onClose && (
        <Box gridArea="close" alignSelf="start">
          <IconButton
            variant="secondary"
            size="small"
            icon={MiniDeleteIcon}
            onClick={onClose}
            tip="Close banner"
            tipProps={{ alignment: 'bottom-center', placement: 'floating' }}
          />
        </Box>
      )}
    </BannerContainer>
  );
};
