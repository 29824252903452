import { Popover } from '@codecademy/gamut';

import { NotificationsWrapper } from './elements';
import { NotificationsContents } from './NotificationsContents';
import { NotificationsDropdownProps } from './types';

export const NotificationsDropdown: React.FC<NotificationsDropdownProps> = ({
  bellRef,
  handleClose,
  isOpen,
  ...props
}) => {
  return (
    <Popover
      align="right"
      verticalOffset={1}
      outline
      isOpen={isOpen}
      targetRef={bellRef}
      widthRestricted={false}
    >
      <NotificationsWrapper>
        <NotificationsContents {...props} handleClose={handleClose} />
      </NotificationsWrapper>
    </Popover>
  );
};
