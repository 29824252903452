import React, { useEffect, useRef } from 'react';

import { AppHeaderLink } from '../../AppHeaderLink';
import { useAppHeaderMenuContext } from '../../AppHeaderMenuProvider';
import { AppHeaderDropdownLinkProps } from './types';

export const AppHeaderMenuItem: React.FC<AppHeaderDropdownLinkProps> = ({
  role,
  ...props
}) => {
  const { menuItems, setMenuItems } = useAppHeaderMenuContext();
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (setMenuItems && ref?.current) {
      setMenuItems(menuItems.add(ref.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppHeaderLink role={role} ref={ref} {...props} />;
};
