import { FeatureFlag } from '@mono/data/feature-flags';
import { useFeature } from '@optimizely/react-sdk';
import { logError } from '@mono/data/logging';

/**
 * For use client side in components.
 * We are wrapping the SDK useFeature hook in a try/catch to not throw errors
 * and instead return false and log the error
 */
export const useOptimizelyFeature = <F extends FeatureFlag>(featureFlag: F) => {
  try {
    const [isFeatureEnabled] = useFeature(featureFlag);
    return isFeatureEnabled;
  } catch (error) {
    logError(error);
    return false;
  }
};
