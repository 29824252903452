import { UserVisitData } from '@mono/tracking';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useUserVisitContext } from '../contexts/UserVisitContext';
import { trackUserVisit } from './tracking';

export const useUserVisit = (data: UserVisitData) => {
  const [previousTracking, setPreviousTracking] = useState<UserVisitData>();
  const { asPath } = useRouter();
  const pagePathWithTrailingSlash = asPath[0] !== '/' ? `/${asPath}` : asPath;
  const pagePath =
    process.env.NEXT_PUBLIC_BASE_PATH + pagePathWithTrailingSlash;

  const { referrerOverwrite } = useUserVisitContext();
  useEffect(() => {
    if (!previousTracking || !isEqual(previousTracking, data)) {
      setPreviousTracking(data);
      if (
        !window.document.referrer ||
        window.document.referrer.includes(process.env.NEXT_PUBLIC_BASE_PATH)
      ) {
        try {
          trackUserVisit({ ...data, referrer: referrerOverwrite });
        } catch (err) {
          throw new Error(`Could not track user visit: ${err}`);
        }
      } else {
        trackUserVisit({ ...data });
      }
    }
  }, [previousTracking, data, asPath, pagePath, referrerOverwrite]);
};
