import { focusVisibleStyle } from '@codecademy/gamut';
import { Background, css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

import { EmptyNotification } from './EmptyNotification';
import { NotificationItem } from './NotificationItem';
import { Notification } from './typings';

export type NotificationListProps = {
  onDismiss?: (notification: Notification) => void;
  notifications: Notification[];
  onNotificationClick?: (notification: Notification) => void;
  headerElementId?: string; // Used for aria-labelledby for the list.
};

const listFocusStyle = focusVisibleStyle('-4px');

const UnstyledUnorderedList = styled(Background)(
  css({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    ...listFocusStyle,
  })
);

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  onNotificationClick,
  onDismiss,
  headerElementId,
}) => {
  const listRef = React.useRef<HTMLDivElement>(null);
  const onDismissHandler = async (notification: Notification) => {
    if (listRef?.current) {
      await listRef.current.focus();
    }
    await onDismiss?.(notification);
  };

  return (
    <UnstyledUnorderedList
      aria-labelledby={headerElementId}
      aria-live="polite"
      aria-relevant="additions"
      as="ul"
      bg="background"
      ref={listRef}
      tabIndex={-1}
    >
      {isEmpty(notifications) ? (
        <EmptyNotification />
      ) : (
        notifications.map((notification: Notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            handleClick={() => onNotificationClick?.(notification)}
            handleDismiss={() => onDismissHandler(notification)}
          />
        ))
      )}
    </UnstyledUnorderedList>
  );
};
