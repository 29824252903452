import { logError } from '@mono/data/logging';
import { ReactSDKClient, useExperiment } from '@optimizely/react-sdk';
import {
  BasicExperimentVariant,
  Experiment,
  ExperimentVariant,
} from './typings/experiments';

/**
 * For use client side in components.
 * We are wrapping the SDK useExperiment hook in a try/catch to not throw errors
 * and instead return undefined if optimizely is not initialized upstream.
 * Note that the useExperiment hook activates the user into an experiment if
 * not previously activated.
 */
export const useOptimizelyExperiment = <E extends Experiment>(
  experiment: E
) => {
  try {
    const [variation] = useExperiment(experiment);
    return variation;
  } catch (error) {
    logError(`Couldn't load experiment ${experiment}: ${error}`);
    return undefined;
  }
};

/**
 * Note: activates the user into the experiment if not previously activated.
 * If you want to check the experiment variation key without activating the
 * experiment use useHasExperimentVariant in ~/libs/experiments/useExperiment.
 */
export const useOptimizelyHasExperimentVariant = <E extends Experiment>(
  experiment: E,
  variant: ExperimentVariant<E>
) => useOptimizelyExperiment(experiment) === variant;

export const useOptimizelyIncludesExperimentVariant = <E extends Experiment>(
  experiment: E,
  variants: ExperimentVariant<E>[]
) => {
  const variation = useOptimizelyExperiment(experiment);

  if (!variation) {
    return false;
  }

  return variants.some((option) => option === variation);
};

// For use server side in getServerSideProps.
// An experiment is active if it is defined and set to a variant other than 'control'
export const isOptimizelyExperimentActive = (
  optimizely: ReactSDKClient | undefined,
  experiment: Experiment
) => {
  const variation = optimizely?.activate(experiment);
  return (
    variation !== undefined &&
    variation !== null &&
    variation !== BasicExperimentVariant.CONTROL
  );
};
