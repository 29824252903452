import {
  ContentContainer,
  FillButton,
  FlexBox,
  TextButton,
} from '@codecademy/gamut';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { AppHeaderLink } from '../../AppHeader/AppHeaderElements/AppHeaderLink';
import { AppHeaderListItem } from '../../AppHeader/AppHeaderElements/AppHeaderListItem';
import {
  AppHeaderAction,
  AppHeaderClickHandler,
  AppHeaderDropdownItem,
  AppHeaderItem,
} from '../../AppHeader/shared';
import { login, signUp } from '../../GlobalHeader/GlobalHeaderItems';
import { AppHeaderSubNavMobile } from '../AppHeaderSubMenuMobile';
import { MobileUlWrapper } from '../AppHeaderSubMenuMobile/elements';
import { AppHeaderSubMenuRow } from '../AppHeaderSubMenuTarget';

export type AppHeaderMainMenuMobileProps = AppHeaderAction & {
  items: AppHeaderItem[];
  getItemType: (type: string | undefined) => void;
  isAnon: boolean;
  handleCloseMainMenu: () => void;
};

export const AppHeaderMainMenuMobile: React.FC<
  AppHeaderMainMenuMobileProps
> = ({ action, items, getItemType, isAnon, handleCloseMainMenu }) => {
  const mainMenuContainer = useRef<HTMLUListElement>(null);
  const [subMenuItem, setSubMenuItem] = useState<AppHeaderDropdownItem>();

  useEffect(() => {
    if (mainMenuContainer?.current && subMenuItem === undefined) {
      mainMenuContainer.current.focus();
    }
  }, [subMenuItem]);

  const openSubMenu = (
    event: React.MouseEvent,
    item: AppHeaderDropdownItem
  ) => {
    action(event, item);
    setSubMenuItem(item);
    getItemType(item.type);
  };

  const closeSubMenu = () => {
    setSubMenuItem(undefined);
    getItemType(undefined);
  };

  const mapItemToElement = (
    item: AppHeaderItem,
    action: AppHeaderClickHandler
  ) => {
    switch (item.type) {
      case 'link':
        return (
          <AppHeaderLink action={action} item={item} key={item.id} showIcon />
        );
      case 'dropdown':
      case 'profile-dropdown':
      case 'catalog-dropdown':
      case 'resources-dropdown':
        return (
          <AppHeaderSubMenuRow
            key={item.id}
            item={item}
            openSubMenu={openSubMenu}
          />
        );
      case 'fill-button':
        return (
          <FillButton
            data-testid={item.dataTestId}
            href={item.href}
            onClick={(event: React.MouseEvent) => action(event, item)}
            mt={24}
            key={item.id}
          >
            {item.text}
          </FillButton>
        );
      case 'text-button':
        return (
          <TextButton
            mt={16}
            key={item.id}
            data-testid={item.dataTestId}
            href={item.href}
            onClick={(event: React.MouseEvent) => action(event, item)}
          >
            {item.text}
          </TextButton>
        );
      default:
        return <></>;
    }
  };

  return subMenuItem ? (
    <AppHeaderSubNavMobile
      handleCloseSubMenu={closeSubMenu}
      action={action}
      item={subMenuItem}
      handleCloseMainMenu={handleCloseMainMenu}
    />
  ) : (
    <MobileUlWrapper>
      <ContentContainer as="li">
        <MobileUlWrapper as="ul" tabIndex={-1} ref={mainMenuContainer}>
          {items.map((item) => (
            <AppHeaderListItem key={item.id}>
              {mapItemToElement(item, action)}
            </AppHeaderListItem>
          ))}
        </MobileUlWrapper>
      </ContentContainer>
      {isAnon && (
        <FlexBox
          as="li"
          alignItems="baseline"
          justifyContent="center"
          pt={16}
          borderTop={1}
          borderColor="navy-300"
        >
          <FillButton
            data-testid={signUp.dataTestId}
            href={signUp.href}
            onClick={(event: React.MouseEvent) => action(event, signUp)}
            key={signUp.id}
          >
            {signUp.text}
          </FillButton>

          <TextButton
            key={login.id}
            data-testid={login.dataTestId}
            href={login.href}
            onClick={(event: React.MouseEvent) => action(event, login)}
          >
            {login.text}
          </TextButton>
        </FlexBox>
      )}
    </MobileUlWrapper>
  );
};
