import { Box, FillButton, FlexBox, Text } from '@codecademy/gamut';
import { MiniOpenIcon } from '@codecademy/gamut-icons';
import React from 'react';

import { pastDueErrorMessages } from '../pastDueMessages';

export interface PastDueModalProps {
  pastDueErrorCode: string;
  isPaypalPayment: boolean;
  isSilverPlan: boolean;
  closeButton: React.ReactNode;
  updatePaymentButton: React.ReactNode;
}

const PayPalPastDueModal: React.FC<{ isSilverPlan: boolean }> = ({
  isSilverPlan,
}) => {
  return (
    <Box>
      <Text mb={12}>
        Your payment did not go through. To keep learning with Codecademy{' '}
        {isSilverPlan ? 'Plus' : 'Pro'}, please update your payment method
        through PayPal.
      </Text>
      <Text mb={12}>
        After logging into PayPal, go to Settings {'>'} Payments {'>'} Automatic
        payments. Select Codecademy to update your payment method.
      </Text>
    </Box>
  );
};

const GoToPayPalButton = () => (
  <FillButton href="https://www.paypal.com" target="_blank">
    <Text mr={10 as 0}>Go to PayPal </Text>
    <MiniOpenIcon />
  </FillButton>
);

export const PastDueModalContents: React.FC<
  React.PropsWithChildren<PastDueModalProps>
> = ({
  pastDueErrorCode,
  isPaypalPayment,
  isSilverPlan,
  closeButton,
  updatePaymentButton,
}) => {
  const pastDueErrorMessage =
    pastDueErrorMessages(isSilverPlan, false)[pastDueErrorCode] ||
    pastDueErrorMessages(isSilverPlan, false).default;

  return (
    <>
      <FlexBox column>
        {isPaypalPayment ? (
          <PayPalPastDueModal isSilverPlan />
        ) : (
          <Box>
            <Text mb={12}>{pastDueErrorMessage.text1}</Text>
            <Text mb={12}>{pastDueErrorMessage.text2}</Text>
          </Box>
        )}
      </FlexBox>
      <FlexBox justifyContent="end" alignItems="end">
        {closeButton}
        {isPaypalPayment ? <GoToPayPalButton /> : updatePaymentButton}
      </FlexBox>
    </>
  );
};
