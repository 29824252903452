import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import Cookies from 'universal-cookie';

export const NEXTJS_CONTEXT_PROP_NAME = '__NEXTJS_CONTEXT__';

export type PagePropsWithNextJSContext = {
  [NEXTJS_CONTEXT_PROP_NAME]: {
    cookie: string | null;
    isPreview: boolean;
  };
};

export const exportNextJSContextAsProps = (
  context: GetServerSidePropsContext | (GetStaticPropsContext & { req?: never })
): PagePropsWithNextJSContext => ({
  [NEXTJS_CONTEXT_PROP_NAME]: {
    cookie: context.req?.headers?.cookie ?? null,
    isPreview: !!context.preview,
  },
});

export const useSSRCookies = (pageProps: PagePropsWithNextJSContext) =>
  new Cookies(pageProps?.[NEXTJS_CONTEXT_PROP_NAME]?.cookie);

export const useIsPreviewNextJS = (pageProps: PagePropsWithNextJSContext) =>
  pageProps?.[NEXTJS_CONTEXT_PROP_NAME]?.isPreview;
