import {
  Anchor,
  Box,
  Column,
  FillButton,
  FlexBox,
  FloatingCard,
  FormRequiredText,
  GridBox,
  IconButton,
  Input,
  Overlay,
  Text,
} from '@codecademy/gamut';
import {
  CheckFilledIcon,
  MiniCopyIcon,
  MiniDeleteIcon,
} from '@codecademy/gamut-icons';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParam } from 'react-use';

import { BASE_STATIC_ASSET_PATH } from '../../../remoteAssets/components';
import { FooterLinkItem } from '../../FooterLinks';
import type { GlobalFooterClickHandler } from '../../types';
import { ReferralForm } from './ReferralForm';
import { ReferralLinkSocialMediaSharing } from './ReferralLinkSocialMediaSharing';
import type { ReferralRockUrls } from './types';

const WhiteOverlay = styled(Overlay)`
  background-color: rgba(255, 255, 255, 0.95);
`;

const StyledFloatingCard = styled(FloatingCard)`
  padding: 0;
`;

const Image = Box.withComponent('img');

const ReferralRockLink = styled.li`
  &:not(:last-of-type)::after {
    content: '|';
    margin: 0 0.375rem;
    font-size: 1rem;
  }
`;

export const ReferAFriendLinkWithModal: React.FC<{
  referralRockUrls: ReferralRockUrls;
  onClick: GlobalFooterClickHandler;
}> = ({ referralRockUrls, onClick }) => {
  const actionParam = useSearchParam('action');
  const urlParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams();

  const [isOpen, setIsOpen] = useState(
    urlParams.get('action') === 'referAFriend'
  );
  const [referralUrl, setReferralUrl] = useState('');
  const [showLinkCopiedMessage, setShowLinkCopiedMessage] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const referralRockLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (actionParam === 'referAFriend') {
      setIsOpen(() => true);
    }
  }, [actionParam]);

  // linter disabled to prevent refer a friend link from being focused on initial render
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else if (!isOpen) {
      referralRockLinkRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCloseModal = async () => {
    if (window && actionParam) {
      window.history.pushState({}, '', window.location.pathname);
    }

    setIsOpen(false);
  };

  const copyUrlToClipboardWithTimeout = async (referralUrl: string) => {
    await navigator.clipboard.writeText(referralUrl);
    setShowLinkCopiedMessage(true);

    const delay = setTimeout(() => setShowLinkCopiedMessage(false), 2000);
    return () => clearTimeout(delay);
  };

  const referralRockLinks = [
    {
      href: referralRockUrls.tos,
      text: 'Terms and conditions',
    },
    {
      href: referralRockUrls.stats,
      text: 'My referral stats',
    },
  ];

  const modalImageAltText =
    'Two individuals sitting on the grass while working on a shared laptop';

  return (
    <>
      <FooterLinkItem>
        <Anchor
          variant="interface"
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            onClick({ event, target: 'refer-a-friend' });
            setIsOpen(true);
          }}
        >
          Refer a friend
        </Anchor>
      </FooterLinkItem>
      <WhiteOverlay
        isOpen={isOpen}
        clickOutsideCloses
        onRequestClose={handleCloseModal}
        shroud
      >
        <StyledFloatingCard maxWidth={{ _: 400, md: 900 }}>
          <GridBox
            gridAutoFlow={{ _: 'row', md: 'column' }}
            gridAutoColumns="1fr"
          >
            <Column display={{ _: 'none', xs: 'grid', md: 'none' }} height={48}>
              <IconButton
                aria-label="Close"
                alignSelf="center"
                icon={MiniDeleteIcon}
                justifySelf="right"
                mr={8}
                onClick={handleCloseModal}
                size="small"
                variant="secondary"
                tip="Close referral modal"
              />
            </Column>
            <Column>
              <Image
                src={`${BASE_STATIC_ASSET_PATH}/referral-rock/modal-desktop.webp`}
                alt={modalImageAltText}
                display={{ _: 'none', md: 'block' }}
                width="100%"
              />
              <Image
                src={`${BASE_STATIC_ASSET_PATH}/referral-rock/modal-mobile.webp`}
                alt={modalImageAltText}
                display={{ _: 'none', xs: 'block', md: 'none' }}
                width="100%"
              />
            </Column>
            <Column p={24}>
              <Box mb={32}>
                <FlexBox justifyContent="space-between" mb={12}>
                  <Text
                    as="h2"
                    fontSize={34}
                    pr={{ _: 0, md: 16 }}
                    maxWidth={{ _: 230, xs: 352 }}
                  >
                    Refer a friend to Codecademy
                  </Text>
                  <IconButton
                    tip="Close referral modal"
                    display={{ _: 'block', xs: 'none', md: 'block' }}
                    icon={MiniDeleteIcon}
                    onClick={handleCloseModal}
                    size="small"
                    variant="secondary"
                  />
                </FlexBox>
                <Text>
                  Your friend gets 50% off a Pro, Plus, or Pro Student annual
                  plan — and you get a $20 Amazon gift card.
                </Text>
                <FormRequiredText pt={4} variant="p-small" />
              </Box>
              {referralUrl ? (
                <Box>
                  <Input
                    name="referral url"
                    aria-label="Referral link"
                    type="text"
                    defaultValue={referralUrl}
                    width="fit-content"
                  />
                  <Box mt={12}>
                    {showLinkCopiedMessage ? (
                      <FlexBox mb={40} alignItems="center">
                        <CheckFilledIcon
                          mr={8}
                          mb={2 as 0}
                          color="feedback-success"
                          alignSelf="center"
                        />
                        <Text color="feedback-success">Link copied!</Text>
                      </FlexBox>
                    ) : (
                      <FillButton
                        onClick={() =>
                          copyUrlToClipboardWithTimeout(referralUrl)
                        }
                        width="100%"
                        mb={24}
                      >
                        <MiniCopyIcon color="white" pr={8} />
                        Copy link
                      </FillButton>
                    )}
                  </Box>
                  <ReferralLinkSocialMediaSharing referralUrl={referralUrl} />
                </Box>
              ) : (
                <ReferralForm setReferralUrl={setReferralUrl} />
              )}
              <FlexBox
                as="ul"
                listStyle="none"
                m={0}
                p={0}
                mt={24}
                justifyContent="center"
              >
                {referralRockLinks.map(({ href, text }) => (
                  <ReferralRockLink key={href}>
                    <Anchor href={href} fontSize={14} variant="interface">
                      {text}
                    </Anchor>
                  </ReferralRockLink>
                ))}
              </FlexBox>
            </Column>
          </GridBox>
        </StyledFloatingCard>
      </WhiteOverlay>
    </>
  );
};
