import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';

export enum ApolloClientService {
  Monolith = 'monolith',
  Contentful = 'contentful',
  ContentfulLandingPage = 'contentfulLandingPage',
  GraphqlGateway = 'graphqlGateway',
}

export type CreateApolloOptions = {
  jwt?: string;
};

export type CreateApolloContext = Partial<GetServerSidePropsContext>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}

export type MultiApolloConfig = {
  uri: string;
  headers?: HeadersInit;
  generatedTypes: PossibleTypesResultData;
};
export type ApolloServiceConfig = Record<
  ApolloClientService,
  MultiApolloConfig
>;

export type InitializeApolloOptions = {
  initialState?: NormalizedCacheObject;
  context?:
    | GetServerSidePropsContext
    | (GetStaticPropsContext & { req?: never });
  /**
   * Optional Codecademy User JWT for authenticating with services like graphql_gateway
   */
  jwt?: string;
  apolloServiceConfig?: Partial<ApolloServiceConfig>;
};

export type InitializeApollo = (
  options?: InitializeApolloOptions
) => ApolloClient<NormalizedCacheObject>;
