import { createFontLinks } from '@codecademy/gamut-styles';
import Head from 'next/head';

import { useGetCanonicalUrl } from './useGetCanonicalUrl';

export type BaseMeta = {
  canonicalPath?: string;
};

export const useGlobalHead = (meta: BaseMeta) => {
  const canonicalUrl = useGetCanonicalUrl(meta.canonicalPath);

  return (
    <Head>
      <link
        href={`${process.env.NEXT_PUBLIC_BASE_PATH}/favicon.ico`}
        rel="shortcut icon"
        type="image/x-icon"
      />
      <link
        href="/blog.rss"
        rel="alternate"
        title="Codecademy Blog"
        type="application/rss+xml"
      />
      <link rel="canonical" href={canonicalUrl} />

      <meta content="app-id=1376029326" name="apple-itunes-app" />
      <meta name="theme-color" content="#10162F" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      {createFontLinks()}
    </Head>
  );
};
