import { KeyboardEvent } from 'react';

interface KeyboardNavigation {
  back: (arg0: boolean) => void;
  next: (arg0: boolean) => void;
  first: () => void;
  last: () => void;
  closeDropdown: () => void;
}

export const KEY_CODES = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  END: 'End',
  ENTER: 'Enter',
  ESC: 'Escape',
  HOME: 'Home',
  SPACE: ' ',
  TAB: 'Tab',
} as const;

export const useOnEscHandler = (
  escapeHandler: (e?: KeyboardEvent<HTMLDivElement>) => void
) => {
  return (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === KEY_CODES.ESC) {
      e.stopPropagation();
      e.preventDefault();
      escapeHandler(e);
    }
  };
};

export const useMenuKeyboardNavigation = ({
  back,
  next,
  first,
  last,
  closeDropdown,
}: KeyboardNavigation) => {
  return (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey && e.code === KEY_CODES.TAB) {
      e.stopPropagation();
      back(false);
      return;
    }
    switch (e.code) {
      case KEY_CODES.TAB:
        e.stopPropagation();
        next(false);
        break;
      case KEY_CODES.DOWN:
        e.stopPropagation();
        e.preventDefault();
        next(true);
        break;
      case KEY_CODES.UP:
        e.stopPropagation();
        e.preventDefault();
        back(true);
        break;
      case KEY_CODES.HOME:
        e.stopPropagation();
        e.preventDefault();
        first();
        break;
      case KEY_CODES.END:
        e.stopPropagation();
        e.preventDefault();
        last();
        break;
      case KEY_CODES.ESC:
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        break;
    }
  };
};
