import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';

import { useGetCanonicalUrl } from './useGetCanonicalUrl';

export const defaultMetaImage =
  'https://images.codecademy.com/social/logo-codecademy-social.png';

export type GlobalMeta = {
  description: string;
  title: string;
  noIndex?: boolean;
  noFollow?: boolean;
  image?: string;
  pageSchema?: ReturnType<typeof jsonLdScriptProps>;
  canonicalPath?: string;
};

export const useGlobalMeta = (meta: GlobalMeta) => {
  const title = `${meta.title} | Codecademy`;
  const robotsMetaTagElements: string[] = [];
  if (meta.noIndex) {
    robotsMetaTagElements.push('noindex');
  }
  if (meta.noFollow) {
    robotsMetaTagElements.push('nofollow');
  }
  const robotsMetaTag = robotsMetaTagElements.join(', ');

  const canonicalUrl = useGetCanonicalUrl(meta.canonicalPath);

  return (
    <Head>
      {/*
          there is a bug where script tags can cause duplicate meta tags client side.
          loading the script tag first seems to resolve the issue, so please do not move this.
          https://github.com/vercel/next.js/issues/17085
       */}
      {meta.pageSchema && (
        <script type="application/ld+json" {...meta.pageSchema} />
      )}

      <meta content={meta.description} name="description" />

      <meta content="307818116683104" property="fb:app_id" />
      <meta content="codecademy" property="fb:profile_id" />

      <meta content="Codecademy" property="og:site_name" />
      <meta content="website" property="og:type" />
      <meta content={title} property="og:title" />
      <meta content={meta.description} property="og:description" />
      <meta content="true" property="og:rich_attachment" />
      <meta content={meta.image ?? defaultMetaImage} property="og:image" />
      <meta content={canonicalUrl} property="og:url" />

      <meta content="summary_large_image" name="twitter:card" />
      <meta content="@codecademy" name="twitter:site" />
      <meta content={title} name="twitter:title" />
      <meta content={meta.description} name="twitter:description" />
      <meta content={meta.image ?? defaultMetaImage} name="twitter:image" />
      {robotsMetaTag && <meta name="robots" content={robotsMetaTag} />}
      <meta content={title} itemProp="name" />
      <meta content={meta.description} itemProp="description" />
      <meta content={meta.image ?? defaultMetaImage} itemProp="image" />

      <title>{title}</title>
    </Head>
  );
};
