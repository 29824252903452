import { Anchor, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React, { ComponentProps, forwardRef } from 'react';

import {
  AppHeaderAction,
  AppHeaderLinkItem,
  appHeaderMobileBreakpoint,
} from '../../shared';

export type AppHeaderLinkProps = ComponentProps<typeof Anchor> &
  AppHeaderAction & {
    item: AppHeaderLinkItem;
    role?: string;
    showIcon?: boolean;
    onKeyDown?: (event: React.KeyboardEvent) => void;
  };

const StyledAnchor = styled(Anchor)(
  css({
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
    lineHeight: 'base',
    minWidth: '0',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  })
);

export const AppHeaderLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  AppHeaderLinkProps
>(
  (
    {
      action,
      item,
      showIcon = false,
      mx = { _: 0, [appHeaderMobileBreakpoint]: 24 },
      py = { _: 16, [appHeaderMobileBreakpoint]: 8 },
      role,
      ...props
    },
    ref
  ) => {
    const Icon = item.icon;

    return (
      <StyledAnchor
        data-testid={item.dataTestId}
        href={item.href}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          action(event, item)
        }
        target={item.newTab ? 'blank' : ''}
        variant="interface"
        // makes sure this is level with our dropdowns, which have a 1px height differential
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mb={{ _: 0, [appHeaderMobileBreakpoint]: 1 as any }}
        mx={mx}
        py={py}
        role={role}
        {...props}
        ref={ref}
      >
        {showIcon && Icon && <Icon mr={16} size={24} aria-hidden />}
        {item.text}
        {item.badge}
        {role === 'menuitem' && <Text screenreader> Link</Text>}
      </StyledAnchor>
    );
  }
);
