export const getLocalStorageItem = <Value>(
  key: string,
  defaultValue: Value
) => {
  if (typeof localStorage === 'undefined') {
    return defaultValue;
  }

  try {
    const stored = localStorage.getItem(key);
    return stored === null ? defaultValue : (JSON.parse(stored) as Value);
  } catch {
    return defaultValue;
  }
};
