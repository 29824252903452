import { Badge, Box, FlexBox, MenuItem, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { popularContent, popularSearchTerms } from './consts';
import { SemiboldSearchIcon } from './SearchPane';

const StyledMenuItem = styled(MenuItem)`
  &:focus-visible:after {
    left: -4px;
  }
  padding: 8px 0;
`;

interface PopularSearchesProps {
  navigateToSearch: (searchTerm: string) => void;
  onTrackingClick: (
    target: string,
    extraTrackingProps?: Record<string, string>
  ) => void;
}

export const PopularSearches: React.FC<PopularSearchesProps> = ({
  navigateToSearch,
  onTrackingClick,
}) => {
  const isMobile = theme.breakpoints.xs || theme.breakpoints.sm;

  return (
    <Box mb={24} as="li">
      <Text as="h2" variant="title-xs" mb={12}>
        Popular searches
      </Text>
      <FlexBox as="ul" listStyle="none" p={0} flexDirection="column">
        {popularSearchTerms.map((term, i) => (
          <StyledMenuItem
            itemType="link"
            key={term}
            id={term}
            href={`/search?query=${encodeURIComponent(term)}`}
            onClick={() => {
              navigateToSearch(term);
              onTrackingClick('popular_search_term', {
                misc: JSON.stringify({
                  value: term,
                  index: i,
                }),
              });
            }}
            pl={0}
          >
            <SemiboldSearchIcon mb={2 as 0} size={14} strokeWidth={8} />
            <Text variant={isMobile ? 'p-base' : 'p-large'} ml={12}>
              {term}
            </Text>
          </StyledMenuItem>
        ))}
      </FlexBox>
    </Box>
  );
};

interface PopularContentProps {
  onTrackingClick: (
    target: string,
    extraTrackingProps?: Record<string, string>
  ) => void;
}

export const PopularContent: React.FC<PopularContentProps> = ({
  onTrackingClick,
}) => {
  const isMobile = theme.breakpoints.xs || theme.breakpoints.sm;

  return (
    <Box mb={24} as="li">
      <Text as="h2" variant="title-xs" mb={12}>
        What our members are learning
      </Text>
      <FlexBox as="ul" listStyle="none" p={0} flexDirection="column">
        {popularContent.map((c, i) => (
          <StyledMenuItem
            itemType="link"
            key={c.title}
            href={c.href}
            pl={0}
            onClick={() =>
              onTrackingClick('popular_content', {
                slug: c.slug,
                ...(c.contentId ? { content_id: c.contentId } : {}),
                misc: JSON.stringify({
                  content_type: c.type.toLowerCase(),
                  index: i,
                  href: c.href,
                }),
              })
            }
          >
            <FlexBox flexDirection="row" alignItems="center">
              <Text variant={isMobile ? 'p-base' : 'p-large'} mr={12}>
                {c.title}
              </Text>
              <Badge size="sm" variant="tertiary">
                {c.type}
              </Badge>
            </FlexBox>
          </StyledMenuItem>
        ))}
      </FlexBox>
    </Box>
  );
};
