import { Box, IconButton, TextButton } from '@codecademy/gamut';
import { MiniDeleteIcon } from '@codecademy/gamut-icons';
import { ReactElement, useState } from 'react';

import { defaultDisplayLimit } from './constants';
import { NotificationsProps } from './types';

interface NotificationButtons
  extends Pick<NotificationsProps, 'notifications'> {
  clearAllButton: ReactElement | null;
  closeButton: ReactElement;
  showMoreButton: ReactElement | null;
}

export const useNotificationButtons = ({
  actions,
  handleClose,
  notifications,
  notificationListRef,
}: NotificationsProps): NotificationButtons => {
  const [showMore, setShowMore] = useState(false);
  const [displayLimit, amountAdjective] = showMore
    ? [notifications.length, 'Less']
    : [defaultDisplayLimit, 'More'];

  const closeButton = (
    <IconButton icon={MiniDeleteIcon} onClick={handleClose} tip="Close" />
  );

  if (notifications.length === 0) {
    return {
      clearAllButton: null,
      closeButton,
      showMoreButton: null,
      notifications,
    } as const;
  }

  const clearAllButton =
    notifications.length > 0 &&
    (notifications.length <= displayLimit || showMore) ? (
      <TextButton
        onClick={() => {
          actions.clear();
          actions.track('notification_clear_all');
          notificationListRef?.current?.focus();
        }}
        aria-label={`Clear all ${notifications.length} notifications`}
        mr={8}
      >
        Clear All
      </TextButton>
    ) : null;

  const showMoreButton =
    notifications.length <= defaultDisplayLimit ? null : (
      <Box px={32}>
        <TextButton
          onClick={() => {
            setShowMore(!showMore);

            if (!showMore) {
              actions.track('notification_show_more');
              actions.read(notifications.slice(defaultDisplayLimit));
            }
          }}
        >
          Show {amountAdjective}
        </TextButton>
      </Box>
    );

  return {
    clearAllButton,
    closeButton,
    showMoreButton,
    notifications: notifications.slice(0, displayLimit),
  } as const;
};
