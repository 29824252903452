import { initializeGTM } from './gtm';
import { initializeOneTrust } from './onetrust';
import { TrackingWindow } from './types';

export type TrackingIntegrationsSettings = {
  /**
   * Current environment.
   */
  environment: string;

  /**
   * Global scope (often the window) where globals such as analytics are stored.
   */
  scope: TrackingWindow;

  /**
   * Whether user has opted out or is excluded from external tracking
   */
  optedOutExternalTracking?: boolean;

  /**
   * id for the one trust script being used, defaults to the script for `www.codecademy.com`
   */
  oneTrustScript?: string;
};

/**
 * @see README.md for details and usage.
 */
export const initializeTrackingIntegrations = async ({
  environment,
  scope,
  optedOutExternalTracking,
  oneTrustScript,
}: TrackingIntegrationsSettings) => {
  // 1. Wait 1000ms to allow any other post-hydration logic to run first
  await new Promise((resolve) => setTimeout(resolve, 1000));

  // 2. Load in OneTrust's banner and wait for its `OptanonWrapper` callback
  await initializeOneTrust({ scope, environment, scriptId: oneTrustScript });

  // 3. Load GTM
  initializeGTM({ scope, environment, optedOutExternalTracking });
};
