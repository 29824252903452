import { TrackingWindow } from './types';

export type GTMSettings = {
  environment: string;
  scope: TrackingWindow;
  optedOutExternalTracking?: boolean;
};

export const OPT_OUT_DATALAYER_VAR = 'user_opted_out_external_tracking';

export const initializeGTM = ({
  scope,
  environment,
  optedOutExternalTracking,
}: GTMSettings) => {
  scope.dataLayer ??= [];

  scope.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  if (optedOutExternalTracking) {
    scope.dataLayer.push({ [OPT_OUT_DATALAYER_VAR]: true });
  }

  let preview_env = '';
  if (environment === 'development') {
    preview_env = '&gtm_auth=DoN0WSxjuUkImaph8PYXmA&gtm_preview=env-233';
  } else if (environment === 'staging') {
    preview_env = '&gtm_auth=VrQuCDuWXkLlTwNHJYEKTg&gtm_preview=env-232';
  }

  const gtm = document.createElement('script');
  gtm.async = true;
  gtm.src = `https://www.googletagmanager.com/gtm.js?id=GTM-KTLK85W${preview_env}`;
  document.getElementsByTagName('head')[0].appendChild(gtm);
};
