import { gql } from '@apollo/client';

export const featureFlagsAndExperimentsQuery = gql`
  query featureFlagsAndExperiments {
    me {
      id
      username
      anonymous
      featureFlags
      experiments
      userType: type
      businessUser
      skillsoftUser
    }
  }
`;
