/*
There's probably a better lib this can live in.
Moving here for now as a first step towards
establishing single source of truth across libs/apps.
*/

export const CAREER_JOURNEY_TO_CAREER_PATH_MAP: { [key: string]: string } = {
  'full-stack-engineer': 'full-stack-engineer-career-path',
  'front-end-engineer': 'front-end-engineer-career-path',
  'back-end-engineer': 'back-end-engineer-career-path',
  'computer-science': 'computer-science',
  'data-scientist-aly': 'data-analyst',
  'data-scientist-ml': 'data-science',
};

export const careerPathHasJourney = (pathSlug: string) =>
  !!Object.values(CAREER_JOURNEY_TO_CAREER_PATH_MAP).includes(pathSlug);
