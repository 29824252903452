import { Box, FlexBox, Text } from '@codecademy/gamut';
import { CheckerDense } from '@codecademy/gamut-patterns';
import { useEffect, useRef } from 'react';
import * as React from 'react';

import { useOnEscHandler } from '../AppHeader/shared';
import { NotificationList } from '../NotificationList';
import { Notification } from '../NotificationList/typings';
import { NotificationDialog } from './elements';
import { NotificationsProps } from './types';
import { useNotificationButtons } from './useNotificationButtons';

export const NotificationsContents: React.FC<NotificationsProps> = ({
  actions,
  handleClose,
  notifications,
}) => {
  const notificationListRef = useRef<HTMLDivElement>(null);

  const {
    clearAllButton,
    closeButton,
    showMoreButton,
    notifications: visibleNotifications,
  } = useNotificationButtons({
    actions,
    handleClose,
    notificationListRef,
    notifications,
  });

  const onKeyDown = useOnEscHandler(handleClose);

  const onNotificationClick = (notification: Notification) => {
    actions.click(notification);

    if (notification.unread) {
      actions.read([notification]);
    }
  };

  useEffect(() => {
    notificationListRef?.current?.focus();
  }, [notificationListRef]);

  useEffect(() => {
    const unreadVisibleNotifications = visibleNotifications.filter(
      (notification) => notification.unread
    );

    if (unreadVisibleNotifications.length) {
      actions.read(unreadVisibleNotifications);
    }
  }, [actions, visibleNotifications]);

  return (
    <NotificationDialog
      aria-label={
        visibleNotifications.length
          ? `${notifications.length} unread notifications`
          : `My Notifications`
      }
      aria-modal="true"
      bg="background"
      data-autofocus
      onKeyDown={onKeyDown}
      ref={notificationListRef}
      role="dialog"
      tabIndex={-1}
    >
      <FlexBox
        alignItems="center"
        flexDirection="row"
        height="40"
        justifyContent="space-between"
        mb={16}
        px={32}
      >
        <Text as="h1" fontSize={22}>
          My Notifications
        </Text>
        <FlexBox>
          {clearAllButton}
          {closeButton}
        </FlexBox>
      </FlexBox>
      <Box px={32}>
        <CheckerDense height="1px" display="flex" />
      </Box>
      <Box maxHeight="520px" overflow="auto">
        <Box pb={16}>
          <NotificationList
            notifications={visibleNotifications}
            onDismiss={actions.dismiss}
            onNotificationClick={onNotificationClick}
          />
        </Box>
        {showMoreButton}
      </Box>
    </NotificationDialog>
  );
};
