import React, { useEffect, useRef } from 'react';

import { useAppHeaderDropdownContext } from '../../AppHeaderDropdownProvider';
import { AppHeaderLink } from '../../AppHeaderLink';
import { AppHeaderDropdownLinkProps } from './types';

export const AppHeaderDropdownLink: React.FC<AppHeaderDropdownLinkProps> = ({
  isFirstElem,
  role,
  ...props
}) => {
  const { setFirstItemRef } = useAppHeaderDropdownContext();
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isFirstElem && setFirstItemRef && ref?.current) {
      setFirstItemRef(ref.current);
    }
  }, [setFirstItemRef, ref, isFirstElem]);

  return <AppHeaderLink role={role} ref={ref} {...props} />;
};
